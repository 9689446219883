import {
    Placement,
    PlacementInstructionConfigs,
} from '../../shared/types/reklameMaster';
import { MessageBus } from '@podium/browser';
import { events } from '@schibsted-nmp/advertising-events';
import { getConfig, getFeatureStatus } from '../config';
import { UNLEASH_FEATURE_NAMES } from '../../shared/unleash/feature-names';

export function getType(index: number) {
    const config = getConfig();
    const enableContentfulFetcher = getFeatureStatus(
        UNLEASH_FEATURE_NAMES.enableContentfulFetcher,
    );
    const hasContentMarketing =
        config?.contentMarketing?.length > 0 || enableContentfulFetcher;
    if (hasContentMarketing && index === 1) {
        return 'content-marketing';
    }

    return 'ast';
}

export interface XandPlacement {
    placementId: string;
    adServer: {
        type: 'xandr';
        config: Placement;
    };
    intermingle?: {
        list?: number;
        grid?: number;
    };
}

function getNewPlacementConfig(
    placementInstructionConfigs: PlacementInstructionConfigs,
): XandPlacement[] {
    const { placements } = getConfig();
    const placementMap = new Map<string, { list?: number; grid?: number }>();

    // Iterate through the placement instruction configs to populate the map
    placementInstructionConfigs.forEach((pIC) => {
        pIC.data.forEach(({ intervals, type }) => {
            intervals.forEach(({ placementId, interval }) => {
                if (!placementMap.has(placementId)) {
                    placementMap.set(placementId, {});
                }
                const placementIntervals = placementMap.get(placementId);
                if (type === 'list') {
                    placementIntervals.list = interval;
                } else if (type === 'grid') {
                    placementIntervals.grid = interval;
                }
            });
        });
    });

    // Keep track of the last used interval
    let lastListInterval = 0;
    let lastGridInterval = 0;

    // Transform the map into the desired output format
    const newXandrPlacements: XandPlacement[] = [];

    placementMap.forEach((intervals, placementId) => {
        const placement = placements.find((p) => p.placementId === placementId);
        if (placement) {
            // Update list and grid intervals based on the previous values
            const listInterval = lastListInterval + (intervals.list || 0);
            const gridInterval = lastGridInterval + (intervals.grid || 0);

            newXandrPlacements.push({
                placementId: placement.placementId,
                adServer: {
                    type: 'xandr',
                    config: placement,
                },
                intermingle: {
                    list: listInterval,
                    grid: gridInterval,
                },
            });

            // Update the last used intervals
            lastListInterval = listInterval;
            lastGridInterval = gridInterval;
        }
    });

    return newXandrPlacements;
}

export function companionReadyPlacementListener(
    placementInstructionConfigs: PlacementInstructionConfigs,
) {
    if (!placementInstructionConfigs?.length) return;

    const messageBus = new MessageBus();

    messageBus.publish(
        events.PODLET.channel,
        events.PODLET.PODLET_READY.topic,
        {
            placementInstructionConfigs,
            placementsWithIntermingle: getNewPlacementConfig(
                placementInstructionConfigs,
            ),
        },
    );
    messageBus.publish('advertising-companion', 'podlet-ready', {
        placementInstructionConfigs,
    });
}
