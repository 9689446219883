import * as React from 'react';
import { Native } from '../native/native';
import { getAdObjMock, getContentMarketingMock } from './render-localhost.mock';
import ContentMarketingContainer from '../content-marketing/content-marketing-container';
import { getConfig } from '../../config';
import { useMemo, useState } from 'react';
import {
    DeviceType,
    PageType,
    Subvertical,
    Vertical,
} from '../../../shared/types/ast';

function GridPlaceholder() {
    return (
        <div
            className={
                'radius-8 p-8 flex flex-1 justify-center s-bg-subtle h-148'
            }
        >
            Classified Ad
        </div>
    );
}
function GridContainer(props) {
    const children = React.Children.toArray(props.children);
    return (
        <div className={'grid sm:grid-cols-3 grid-cols-2 gap-8'}>
            {children?.map((child, i) => {
                return (
                    <span key={i}>
                        <GridPlaceholder />
                        {child}
                    </span>
                );
            })}
            <GridPlaceholder />
        </div>
    );
}

const ToggleBtn = ({ onClick, children }) => {
    return (
        <button className={'btn s-bg-primary p-2'} onClick={onClick}>
            {children}
        </button>
    );
};

const ToggleMenu = ({
    adType,
    setAdType,
    vertical,
    setVertical,
    pageType,
    setPageType,
    subvertical,
    setSubvertical,
}) => {
    return (
        <div
            className={
                'z-10 s-bg border border-solid s-border-primary p-4 flex flex-col gap-4 fixed top-0 left-0'
            }
        >
            <div className={'flex gap-4'}>
                <div style={{ width: 100 }}>{adType}</div>

                <ToggleBtn onClick={() => setAdType('content-marketing')}>
                    content-marketing
                </ToggleBtn>
                <ToggleBtn onClick={() => setAdType('native')}>
                    native
                </ToggleBtn>
            </div>
            <div className={'flex gap-4'}>
                <div style={{ width: 100 }}>{vertical}</div>
                <ToggleBtn onClick={() => setVertical('realestate')}>
                    realestate
                </ToggleBtn>
                <ToggleBtn onClick={() => setVertical('car')}>car</ToggleBtn>
                <ToggleBtn onClick={() => setVertical('travel')}>
                    travel
                </ToggleBtn>
                <ToggleBtn onClick={() => setVertical('bap')}>bap</ToggleBtn>
                <ToggleBtn onClick={() => setVertical('job')}>job</ToggleBtn>
                <ToggleBtn onClick={() => setVertical('boat')}>boat</ToggleBtn>
                <ToggleBtn onClick={() => setVertical('mc')}>mc</ToggleBtn>
            </div>
            {vertical === 'travel' && (
                <div className={'flex gap-4'}>
                    <div style={{ width: 100 }}>{subvertical}</div>
                    <ToggleBtn onClick={() => setSubvertical('flight')}>
                        flight
                    </ToggleBtn>
                    <ToggleBtn onClick={() => setSubvertical('hotel')}>
                        hotel
                    </ToggleBtn>
                    <ToggleBtn onClick={() => setSubvertical('prepackage')}>
                        prepackage
                    </ToggleBtn>
                </div>
            )}
            <div className={'flex gap-4'}>
                <div style={{ width: 100 }}>{pageType}</div>
                <ToggleBtn onClick={() => setPageType('section')}>
                    section
                </ToggleBtn>
                <ToggleBtn onClick={() => setPageType('object')}>
                    object
                </ToggleBtn>
                <ToggleBtn onClick={() => setPageType('result')}>
                    result
                </ToggleBtn>
            </div>
        </div>
    );
};
export function LocalHostComponent({
    defaultVertical = 'realestate',
    defaultPageType = 'result',
    defaultAdType = 'native',
    defaultTravelSubvertical = 'flight',
    overrideDeviceType,
    isSnapshot = false,
}: {
    defaultVertical?: Vertical;
    defaultPageType?: PageType;
    defaultTravelSubvertical?: Subvertical;
    defaultAdType?: 'native' | 'content-marketing';
    overrideDeviceType?: DeviceType;
    isSnapshot?: boolean;
}) {
    const { deviceType: deviceTypeFromConfig } = getConfig();
    const deviceType = overrideDeviceType || deviceTypeFromConfig;
    const isMobile = deviceType === 'mobile';

    const [vertical, setVertical] = useState<Vertical>(defaultVertical);
    const [travelSubvertical, setTravelSubvertical] = useState<Subvertical>(
        defaultTravelSubvertical,
    );
    const [pageType, setPageType] = useState<PageType>(defaultPageType);
    const [adType, setAdType] = useState(defaultAdType);

    const subvertical: Subvertical = useMemo(() => {
        if (vertical === 'travel') return travelSubvertical;
        if (vertical === 'realestate') {
            return 'homes';
        } else if (vertical === 'car') {
            return 'used';
        } else if (vertical === 'bap') {
            return 'forsale';
        } else return 'used';
    }, [travelSubvertical, vertical]);

    return (
        <>
            {!isSnapshot && (
                <ToggleMenu
                    adType={adType}
                    setAdType={setAdType}
                    vertical={vertical}
                    setVertical={setVertical}
                    pageType={pageType}
                    setPageType={setPageType}
                    subvertical={travelSubvertical}
                    setSubvertical={setTravelSubvertical}
                />
            )}
            <span className={'relative'}>
                <span className={'inset-0 absolute'} />
            </span>
            <div
                className={'p-16'}
                style={{
                    maxWidth: isMobile ? '100vw' : '80vw',
                    paddingTop: isMobile ? 150 : 0,
                }}
            >
                {adType === 'native' && (
                    <div className={'w-full sm:w-1/2'}>
                        <h1>Native list</h1>
                        <div className={'flex flex-col gap-16'}>
                            <GridPlaceholder />

                            <Native
                                status={'available'}
                                pageType={pageType}
                                vertical={vertical}
                                subvertical={subvertical}
                                grid={false}
                                id={`${vertical}-${pageType}-native-ad`}
                                adObj={getAdObjMock({
                                    targetId: `${vertical}-${pageType}-native-ad`,
                                })}
                            />
                            <GridPlaceholder />
                        </div>

                        <h1>Native Content Marketing from Xandr</h1>
                        <Native
                            pageType={'result'}
                            vertical={'bap'}
                            subvertical={'forsale'}
                            status={'available'}
                            grid={true}
                            id={'result-bap-target'}
                            adObj={getAdObjMock({
                                title: 'content native ad',
                                targetId: `${vertical}-${pageType}-native-ad-content-marketing`,
                                desc2: 'content',
                            })}
                        />
                        <h1>Recirc Grid</h1>
                        <GridContainer>
                            <Native
                                pageType={'result'}
                                vertical={'bap'}
                                subvertical={'forsale'}
                                status={'available'}
                                isRecirc={true}
                                grid={true}
                                id={'result-bap-target'}
                                adObj={getAdObjMock({
                                    title: 'result bap grid',
                                    targetId: `${vertical}-${pageType}-native-ad-grid`,
                                })}
                            />
                        </GridContainer>
                        <h1>Grid</h1>
                        <GridContainer>
                            <Native
                                pageType={'result'}
                                vertical={'bap'}
                                subvertical={'forsale'}
                                status={'available'}
                                isRecirc={true}
                                grid={true}
                                id={'result-bap-target'}
                                adObj={getAdObjMock({
                                    title: 'result bap grid',
                                    targetId: `${vertical}-${pageType}-native-ad-grid`,
                                })}
                            />
                        </GridContainer>
                    </div>
                )}
                {adType === 'content-marketing' && (
                    <div className={'w-full sm:w-1/2'}>
                        <h1>Single</h1>

                        <ContentMarketingContainer
                            id={'content-marketing'}
                            contentMarketing={[
                                getContentMarketingMock(
                                    `${vertical}-${pageType}-content-marketing-ad-11`,
                                ),
                            ]}
                            pageType={pageType}
                            vertical={vertical}
                        />
                        <h1>Double</h1>

                        <ContentMarketingContainer
                            id={'content-marketing'}
                            contentMarketing={[
                                getContentMarketingMock(
                                    `${vertical}-${pageType}-content-marketing-ad-21`,
                                ),
                                getContentMarketingMock(
                                    `${vertical}-${pageType}-content-marketing-ad-22`,
                                ),
                            ]}
                            pageType={pageType}
                            vertical={vertical}
                        />
                        <h1>Triple</h1>

                        <ContentMarketingContainer
                            id={'content-marketing'}
                            contentMarketing={[
                                getContentMarketingMock(
                                    `${vertical}-${pageType}-content-marketing-ad-31`,
                                ),
                                getContentMarketingMock(
                                    `${vertical}-${pageType}-content-marketing-ad-32`,
                                ),
                                getContentMarketingMock(
                                    `${vertical}-${pageType}-content-marketing-ad-33`,
                                ),
                            ]}
                            pageType={pageType}
                            vertical={vertical}
                        />
                    </div>
                )}
            </div>
        </>
    );
}
