import * as React from 'react';
import {
    DeviceType,
    PageType,
    AdObj,
    Size,
    Vertical,
    Subvertical,
} from '../../../shared/types/ast';
import { useAd } from '../../hooks/ad';
import { Placeholder } from '../placeholder';
import { Banner } from '../banner';
import { ErrorBoundary } from '../error-boundary';

export type Props = {
    deviceType: DeviceType;
    vertical?: Vertical;
    subvertical?: Subvertical;
    pageType?: PageType;
};

function Skyscraper(props: Props) {
    const { deviceType } = props;

    const { adObj: skyscraperrightAdObj, sizes: skyscraperrightSizes } = useAd(
        'skyscraperright_1',
        true,
    );

    const { adObj: testAdObj } = useAd('test_1', false);

    const isChatBotAd =
        skyscraperrightAdObj &&
        skyscraperrightAdObj.targetId === 'skyscraperright_1' &&
        skyscraperrightAdObj.source === 'rtb' &&
        !!skyscraperrightAdObj.height &&
        !!skyscraperrightAdObj.width &&
        skyscraperrightAdObj.height === 4 &&
        skyscraperrightAdObj.width === 4;

    switch (deviceType) {
        case 'desktop':
            return (
                <div
                    className={`banners adsbox ${
                        isChatBotAd
                            ? 'banners__right-skyscraper-is-chat-bot'
                            : ''
                    }`}
                    aria-hidden="true"
                >
                    <div>
                        <div className="banners__right-skyscraper">
                            <div
                                id="skyscraperright_1-sticky-container"
                                className="banners__sticky-skyscraper"
                            >
                                <HorseshoeSwitch
                                    className=""
                                    id="skyscraperright_1"
                                    adObj={skyscraperrightAdObj}
                                    sizes={skyscraperrightSizes}
                                />
                            </div>
                        </div>

                        <HorseshoeSwitch
                            className="hidden"
                            id="test_1"
                            adObj={testAdObj}
                        />
                    </div>
                </div>
            );
        case 'tablet':
            return (
                <div
                    className="banners adsbox banners__is-horse-shoe"
                    data-banner-pos="topbanner"
                    aria-hidden="true"
                >
                    <div>
                        <HorseshoeSwitch
                            className="hidden"
                            id="test_1"
                            adObj={testAdObj}
                        />
                    </div>
                </div>
            );
        case 'mobile':
            return (
                <HorseshoeSwitch
                    className="hidden"
                    id="test_1"
                    adObj={testAdObj}
                />
            );
    }
}

type HorseshoeSwitchProps = {
    id: string;
    className?: string;
    proximity?: number;
    adObj?: AdObj;
    sizes?: Size[];
};

function HorseshoeSwitch(props: HorseshoeSwitchProps) {
    const { id, className, adObj } = props;

    if (adObj) {
        return (
            <Banner id={id} targetId={adObj.targetId} className={className} />
        );
    }
    return <Placeholder id={id} className={className} />;
}

const SkyscraperWithErrorBoundary = (props: Props) => {
    return (
        <ErrorBoundary fallback={null}>
            <Skyscraper {...props} />
        </ErrorBoundary>
    );
};

export default SkyscraperWithErrorBoundary;
