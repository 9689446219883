import localStorage from './localStorage';
import Glimr from 'glimr-sdk';
import pulse from '@finn-no/pulse-sdk';
import { HorseshoeConfig } from '../../shared/types/podlet';
import { Keywords } from '../../shared/types/ast';
import { getConfig } from '../config';
import axios from 'axios';

const GLIMR_CLIENT_ID = 'H3IYO2A4LD43YPFZIJLN';
export const GLIMR_TAG_STORAGE_KEY = 'glimrTags';

function getPulseEnvironmentId(): Promise<string | undefined> {
    const isLoggedIn = Boolean(window && window.finnUserId);

    if (isLoggedIn) {
        return Promise.resolve(undefined);
    } else {
        return new Promise((resolve, reject) => {
            pulse.getTracker((tracker) => {
                tracker.getEnvironmentId().then(resolve).catch(reject);
            });
        });
    }
}

const cachePrivacyPermission = async (config, pulseEnvId) => {
    let storedGeoLocationPermission = localStorage.readValue(
        'geolocationPermission',
    );
    if (storedGeoLocationPermission) {
        return Promise.resolve(storedGeoLocationPermission);
    } else {
        // API for fetching towards own backend does not work on mfinn: object pages
        const { isMfinn } = getConfig();
        if (!pulseEnvId || isMfinn || !config.baseUrl) {
            return Promise.resolve(false);
        }

        const { data } = await axios(
            config.baseUrl + `/privacy-settings?pulseId=${pulseEnvId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        const { hasLegalBasis, objections } = data;
        const noLegalBasisButGeolocationNotInObjections =
            !hasLegalBasis &&
            !objections?.includes('sdrn:schibsted:datacategory:geolocation');
        const geolocationPermission =
            hasLegalBasis || noLegalBasisButGeolocationNotInObjections;
        localStorage.persistValue(
            'geolocationPermission',
            geolocationPermission,
        );
        return Promise.resolve(geolocationPermission);
    }
};

export const hasGrantedPermission = async (
    config: HorseshoeConfig,
    pulseId?: string,
) => {
    try {
        const pulseEnvId = pulseId || (await getPulseEnvironmentId());
        return await cachePrivacyPermission(config, pulseEnvId);
    } catch (error) {
        return false;
    }
};

export const loadGlimr = async (): Promise<{
    tags: string[];
    tagMappings: object;
}> => {
    return new Promise((resolve) => {
        Glimr.setTagCacheTimeInSeconds(300);
        // request is cached and does not fire multiple requests to glimr.

        Glimr.getTags(GLIMR_CLIENT_ID, (tags, tagMappings) =>
            resolve({ tags, tagMappings }),
        );
    });
};

export const loadGlimrIfPermission = async (config: HorseshoeConfig) => {
    const hasConsent = await hasGrantedPermission(config);
    if (hasConsent) {
        const glimrTags = await loadGlimr();

        if (glimrTags?.tags) {
            localStorage.persistValue(GLIMR_TAG_STORAGE_KEY, glimrTags?.tags);
        }
    } else {
        localStorage.clearValue(GLIMR_TAG_STORAGE_KEY);
    }
};

export const getGlimrKeywords = (): Keywords => {
    const geoTags = localStorage.readValue(GLIMR_TAG_STORAGE_KEY);
    if (!geoTags) {
        return {};
    }
    return {
        'no-sno-geo': geoTags,
        'no-sno-weather': geoTags,
    };
};
