import { events, messageBus } from '@schibsted-nmp/advertising-events';
import React, { useState, useEffect } from 'react';
import { Switch, Props } from './ad-switch/switch';

export function ContentfulSovFetcher(props: Props) {
    const [shouldLoad, setShouldLoad] = useState(true);

    useEffect(() => {
        messageBus.publish(
            events.PODLET.channel,
            events.PODLET.AD_STYLES.topic,
            events.PODLET.AD_STYLES.sendPayload({
                className: 'content-marketing-ad loaded',
                containerId: props.placementId + '--container',
            }),
        );
    }, [props.placementId]);

    useEffect(() => {
        let unsubscribeContentManagement: () => void;
        function handleContentMarketingFallbackCallback() {
            setShouldLoad(false);
        }
        // check if there already are messages received
        const peekContentMarketingEvent = messageBus.peek(
            events.PODLET.channel,
            events.PODLET.CONTENT_MARKETING_NO_SOV.topic,
        );
        if (peekContentMarketingEvent) {
            handleContentMarketingFallbackCallback();
        }

        unsubscribeContentManagement = messageBus.subscribe(
            events.PODLET.channel,
            events.PODLET.CONTENT_MARKETING_NO_SOV.topic,
            handleContentMarketingFallbackCallback,
        );
        return () => {
            if (unsubscribeContentManagement) {
                unsubscribeContentManagement();
            }
        };
    }, []);

    return shouldLoad ? <contentful-sov-fetcher /> : <Switch {...props} />;
}
