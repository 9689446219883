import * as React from 'react';
import { render } from 'react-dom';
import Board, { Props } from './board';
import { handleAdNotLoaded } from '../../utils/monitoring';

export function renderBoard(id: string, props: Props, callback?: () => void) {
    if (document) {
        const container = document.getElementById(id);
        if (container) {
            container.classList.add('advt-placement');
            render(<Board {...props} />, container, callback);
        } else {
            if (
                id === 'board_1' &&
                props.deviceType !== 'mobile' &&
                props.vertical === 'bap' &&
                props.pageType === 'object'
            ) {
                handleAdNotLoaded(
                    id,
                    `renderBoard did not find id: ${id}`,
                    props,
                );
            }
        }
    }
}
