import * as React from 'react';
import { getSizeFromAdObj, useAd } from '../../hooks/ad';
import { Native } from '../native/native';
import { Banner } from '../banner';
import { Placeholder } from '../placeholder';
import { Fallback } from '../fallback';
import { isNative } from '../../utils/ast';
import { isTakeoverV2 } from '../../utils/takeover';
import { Takeover } from '../takeover';
import {
    DeviceType,
    Vertical,
    Subvertical,
    PageType,
    Features,
    BannerAdObj,
    DefineTagOptions,
} from '../../../shared/types/ast';
import { ErrorBoundary } from '../error-boundary';
import '../../podlet/types';
import { displayAdsLog } from '../../utils/debug';
import { usePlacementStyling } from '../../hooks/placement-styling';

export type Props = {
    placementId: string;
    containerId?: string;
    className?: string;
    grid?: boolean;
    vertical?: Vertical;
    subvertical?: Subvertical;
    deviceType?: DeviceType;
    pageType?: PageType;
    features?: Features;
    slotAd?: boolean;
    isRecirc?: boolean;
    customTag?: DefineTagOptions;
    adLoadedCallback?: (adObj) => void;
};

function AdSwitch(props: Props) {
    const {
        placementId,
        containerId,
        className,
        grid = false,
        vertical,
        subvertical,
        deviceType,
        pageType = 'result',
        features,
        slotAd,
        customTag,
    } = props;
    const { adObj, status, targetId } = useAd(placementId, false, customTag);
    displayAdsLog(`${status} ${targetId}`);

    usePlacementStyling(placementId, containerId, targetId, adObj, status);

    if (adObj) {
        const isRecirc =
            props.isRecirc ||
            placementId?.includes('ad-stream') ||
            placementId?.includes('recirc');
        if (isNative(adObj)) {
            return (
                <Native
                    adObj={adObj}
                    isRecirc={isRecirc}
                    slotAd={slotAd}
                    id={targetId}
                    grid={grid}
                    vertical={vertical}
                    subvertical={subvertical}
                    status={status}
                    pageType={pageType}
                    deviceType={deviceType}
                    features={features}
                />
            );
        } else if (isTakeoverV2(adObj, deviceType)) {
            return (
                <Takeover
                    slotAd={slotAd}
                    id={placementId}
                    targetId={targetId}
                    className={className}
                />
            );
        } else {
            const _adObj = adObj as BannerAdObj;
            return (
                <Banner
                    slotAd={slotAd}
                    id={placementId}
                    grid={grid}
                    targetId={targetId}
                    pageType={pageType}
                    vertical={vertical}
                    className={className}
                    adSize={getSizeFromAdObj(_adObj)}
                />
            );
        }
    }

    if (status === 'failed') {
        return null;
    }
    return (
        <Placeholder id={targetId} className={className}>
            <Fallback id={placementId} deviceType={deviceType} />
        </Placeholder>
    );
}

export const Switch = (props: Props) => {
    return (
        <ErrorBoundary fallback={null}>
            <AdSwitch {...props} />
        </ErrorBoundary>
    );
};
