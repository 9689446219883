// @ts-nocheck
function formatValue(value = '') {
    return value.toLowerCase().split(' ').join('_');
}

export function destination(vertical: string | string[], active: string) {
    if (active) {
        // const active = values[values.length - 1];
        const separated = active.split(';');
        const prefix = `no-sno-finn-${vertical}`;
        return {
            [`${prefix}_continent`]:
                separated.length > 0 ? formatValue(separated[0]) : '',

            [`${prefix}_country`]:
                separated.length > 1 ? formatValue(separated[1]) : '',

            [`${prefix}_region`]:
                separated.length > 2 ? formatValue(separated[2]) : '',

            [`${prefix}_city`]:
                separated.length > 3 ? formatValue(separated[3]) : '',
        };
    }
    return {};
}

function diffList(updatedList = [], existingList = []) {
    if (updatedList.length === existingList.length) {
        // eslint-disable-next-line no-plusplus
        for (let i = updatedList.length; i-- > 0; ) {
            if (updatedList[i] !== existingList[i]) {
                return updatedList[i];
            }
        }
    } else if (updatedList.length) {
        return updatedList[updatedList.length - 1];
    }
    return undefined;
}

type Filter = Record<
    string,
    string | number | string[] | Record<string, string>
>;

export function filterKeywordsLegacy(
    vertical: string | string[],
    filter: Filter,
    existingFilter = {},
) {
    return Object.entries(filter).reduce(
        (previousValue, [key, value]) => {
            switch (key) {
                case 'til': {
                    const active = diffList(value, existingFilter[key]);
                    return {
                        refresh: !!active || previousValue.refresh,
                        keywords: {
                            ...previousValue.keywords,
                            ...destination(vertical, active),
                        },
                    };
                }
                case 'side':
                    return {
                        ...previousValue,
                        refresh:
                            value !== existingFilter[key] ||
                            previousValue.refresh,
                    };

                default:
                    return previousValue;
            }
        },
        {
            refresh:
                Object.keys(filter).length < Object.keys(existingFilter).length,
            keywords: {},
        },
    );
}
