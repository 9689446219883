import * as React from 'react';
import { render } from 'react-dom';
import Skyscraper, { Props } from './skyscraper';

export function renderSkyscraper(
    id: string,
    props: Props,
    callback?: () => void,
) {
    if (document) {
        const container = document.getElementById(id);
        if (container) {
            container.classList.add('advt-placement');
            render(<Skyscraper {...props} />, container, callback);
        }
    }
}
