import { useEffect, useState } from 'react';

function addStyle() {
    const cssId = 'fallback-style';
    const shadowSpinnerCSS = document.getElementById(cssId);
    if (!shadowSpinnerCSS) {
        const style = document.createElement('style');
        style.setAttribute('id', cssId);
        document.head.appendChild(style);
        if (style.sheet) {
            const styleSheet = style.sheet as CSSStyleSheet;
            styleSheet.insertRule(`
                .fallback {
                    animation: fadein 2s forwards;
                }`);
            styleSheet.insertRule(`
                    @keyframes fadein {
                        from { opacity: 0; }
                        to   { opacity: 1; }
                }`);
        }
    }
}

export function useFallback() {
    const [fallback, setFallback] = useState(false);

    useEffect(() => {
        if (document) {
            const horseshoeConfig = document.getElementById('horseshoe-config');
            if (!horseshoeConfig) {
                setFallback(true);
                addStyle();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // We only want this to run once
    return fallback;
}
