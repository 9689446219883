import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { AdObj, NativeAdObj, RtbAdObj } from '../../shared/types/ast';
import { isNative, isNativeVideoOrContent, isTakeover } from '../utils/ast';
import { events, messageBus } from '@schibsted-nmp/advertising-events';

export function usePlacementStyling(
    placementId: string,
    containerId: string,
    targetId: string,
    adObj?: AdObj,
    status?: string,
) {
    const prevPublishedPayloadClassName = useRef<string>('');

    useEffect(() => {
        function publishMessageBus(className: string, containerId: string) {
            if (prevPublishedPayloadClassName.current === className) return;
            prevPublishedPayloadClassName.current = className;
            messageBus.publish(
                events.PODLET.channel,
                events.PODLET.AD_STYLES.topic,
                events.PODLET.AD_STYLES.sendPayload({
                    className,
                    containerId,
                }),
            );
        }

        if (adObj) {
            const contentOrVideo = isNativeVideoOrContent(adObj as NativeAdObj);
            const className = classNames({
                loaded: status === 'available' || status === 'loaded',
                'video-ad sf-advertising-video': contentOrVideo === 'video',
                'content-ad sf-advertising-content':
                    contentOrVideo === 'content',
                'takeover-ad sf-advertising-takeover': isTakeover(
                    placementId,
                    adObj as RtbAdObj,
                ),
                'native-ad sf-advertising-native':
                    adObj && isNative(adObj as NativeAdObj) && !contentOrVideo,
                'banner-ad sf-advertising-banner':
                    adObj &&
                    !isNative(adObj as NativeAdObj) &&
                    adObj?.adType !== 'video',
                failed: status === 'failed',
            });

            publishMessageBus(
                className,
                containerId || `${placementId}--container`,
            );
        } else if (status === 'failed') {
            publishMessageBus(
                'failed',
                containerId || `${placementId}--container`,
            );
        }
    }, [status, containerId, adObj, placementId, targetId]);
}
