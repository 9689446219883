import { NativeAdObj } from '../../../shared/types/ast';
import { ContentMarketing } from '../../../shared/types/contentMarketing';

export const getAdObjMock = ({
    targetId,
    title = 'En fin tittel',
    height = 150,
    width = 300,
    desc2 = '',
}): NativeAdObj => ({
    adType: 'native',
    auctionId: '123123',
    buyerMemberId: 123123,
    width,
    height,
    source: 'rtb',
    tagId: 123,
    creativeId: 123123,
    targetId: targetId,
    native: {
        type: 'netboard',
        title: title || 'Title',
        description: 'Description',
        desc2,
        body: 'En litt lengere body som utfordrer containeren til de grader sånn at vi kan teste forskjellig ting. En litt lengere body som utfordrer containeren til de grader sånn at vi kan teste forskjellig ting. En litt lengere body som utfordrer containeren til ',
        sponsoredBy: 'Sponsored by',
        mainMedia: [
            {
                label: 'label',
                url: `https://via.placeholder.com/${width}x${height}`,
                width,
                weidth: width,
                height,
            },
        ],
        fullText: 'Full text',
        iconImgUrl: `https://via.placeholder.com/${width}x${height}`,
        image: {
            url: `https://via.placeholder.com/${width}x${height}`,
            width,
            height,
        },
        icon: {
            url: `https://via.placeholder.com/${width}x${height}`,
            width,
            height,
        },
        cta: 'cta',
        impressionTrackers: [''],
        clickTrackers: [''],
        clickUrl: `https://via.placeholder.com/${width}x${height}`,
    },
});

export const getContentMarketingMock = (id: string): ContentMarketing => ({
    id,
    title: 'Dette er forskjellen på kompositt, marmor og keramikk',
    text: 'Vedlikeholdsfrie benkeplater:',
    width: 'full',
    variantName: '',
    imagePosition: 'top',
    videoUrl:
        'https://amd-abo.akamaized.net/brandstudio/vod/2023/02/63f72724093e45362c65531c/,480_480_428,640_640_682,720_720_1247,1080_1080_3706,/master.m3u8',
    skin: 'default',
    image: {
        id: '78538104',
        url: 'https://smooth-storage.aptoma.no/users/schibsted/images/78538104.jpg?accessToken=4e152ecbba7ecaae51142d8f7859891b8f4224c0fbe7a474da797cf3d08d0e52',
        width: 1764,
        height: 992,
        aoi: {
            focus: {
                x: 704,
                y: 575,
            },
            width: 500,
            height: 82,
            origin: 'auto',
            x: 454,
            y: 534,
        },
    },
    icon: {
        id: '77699132',
        url: 'https://smooth-storage.aptoma.no/users/schibsted/images/77699132.jpg?accessToken=5cbda090c7bda31add1879347d3895bb02b412d15f1025c887ce1c91b9d35265',
        width: '1024',
        height: '269',
        aoi: {
            focus: {
                x: 512,
                y: 135,
            },
            width: 410,
            height: 108,
            origin: 'auto',
            x: 307,
            y: 81,
        },
    },
    href: 'https://www.aftenposten.no/brandstudio/feature/v/nerostein/oppgrader-kjokkenet-med-ny-benkeplate-b/',
    tag: 'brandstudio',
    sponsor: 'Aftenposten Brandstudio',
});
