import * as React from 'react';
import { Switch } from './ad-switch/switch';
import { Placeholder } from './placeholder';
import { Features, PageType } from '../../shared/types/ast';
import ContentMarketingContainer from './content-marketing/content-marketing-container';
import { getConfig, getFeatureStatus } from '../config';
import { render } from 'react-dom';
import { ContentfulSovFetcher } from './contentfulSovFetcher';
import { UNLEASH_FEATURE_NAMES } from '../../shared/unleash/feature-names';

export type PlacementType = 'ast' | 'shopping' | 'content-marketing';

export type Props = {
    id: string;
    containerId?: string;
    className?: string;
    type: PlacementType;
    grid: boolean;
    showBanners: boolean;
    slotAd?: boolean;
    features?: Features;
};

const { vertical, subvertical, deviceType } = getConfig();

export function Placement(props: Props) {
    const {
        id,
        containerId,
        className,
        type,
        grid,
        showBanners,
        features,
        slotAd,
    } = props;

    if (showBanners) {
        switch (type) {
            case 'ast': {
                return (
                    <Switch
                        containerId={containerId}
                        slotAd={slotAd}
                        placementId={id}
                        className={className}
                        grid={grid}
                        vertical={vertical}
                        subvertical={subvertical}
                        deviceType={deviceType}
                        features={features}
                    />
                );
            }

            case 'content-marketing':
                const enableContentfulFetcher = getFeatureStatus(
                    UNLEASH_FEATURE_NAMES.enableContentfulFetcher,
                );
                if (enableContentfulFetcher) {
                    return (
                        <ContentfulSovFetcher
                            containerId={containerId}
                            slotAd={slotAd}
                            placementId={id}
                            className={className}
                            grid={grid}
                            vertical={vertical}
                            subvertical={subvertical}
                            deviceType={deviceType}
                            features={features}
                        />
                    );
                }
                return (
                    <ContentMarketingContainer
                        id={id}
                        pageType={'result' as PageType}
                    />
                );
        }
    }
    return <Placeholder id={id} />;
}

export function renderPlacement(containerId: string, props: Props) {
    const container = document.getElementById(containerId);
    if (container) {
        container.classList.add('advt-placement');
        // react createRoot
        render(<Placement {...props} containerId={containerId} />, container);
    }
}
