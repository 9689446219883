import { Feed } from './types/reklameMaster';

export function isEmpty(obj?: object) {
    if (obj && typeof obj === 'object') {
        return Object.keys(obj).length === 0;
    }
    return true;
}

export function isValidUrl(url: string) {
    const hostRegex = new RegExp(/^finn$/);
    const tldRegex = new RegExp(/no(:+\d+)?/);
    try {
        const host = new URL(url).host;
        const values = host.split('.').slice(-2);
        return hostRegex.test(values[0]) && tldRegex.test(values[1]);
    } catch (e) {
        return false;
    }
}

export function nrmFeedToString(feed: Feed) {
    return feed
        ? Object.entries(feed).reduce((accumulator, currentValue) => {
              accumulator += `${currentValue[0]}=${currentValue[1]};`;
              return accumulator;
          }, '')
        : '';
}

/**
 * Checks for inequality even if the values are arrays of strings
 * @param object1
 * @param object2
 */
export function keyValuesEqual(object1, object2) {
    if (!isObject(object1) || !isObject(object2)) {
        return false;
    }
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];

        const val1String = Array.isArray(val1) ? val1.join() : val1;
        const val2String = Array.isArray(val2) ? val2.join() : val2;

        if (val1String !== val2String) {
            return false;
        }
    }
    return true;
}
export function isObject(object) {
    return object != null && typeof object === 'object';
}
