import * as React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Placement, Props } from '../placement';
import { getConfig } from '../../config';

let bannerWrappers: HTMLElement[] = [];

function renderPlacement(
    id: string,
    props: Props,
    container: HTMLElement,
    position: number,
) {
    const element = document.createElement('div');
    element.id = `${id}-wrapper`;
    element.setAttribute('style', 'display: flex; margin-bottom: 32px;');
    element.classList.add('advt-placement');
    container.insertBefore(element, container.children[position]);

    bannerWrappers.push(element);
    render(
        <Placement {...props} id={id} className="inline-container" />,
        element,
    );
}

function removeOldBanners() {
    bannerWrappers.forEach((banner) => {
        unmountComponentAtNode(banner);
    });
    bannerWrappers = [];
}
function addNewBanners(containerId: string, props: Props) {
    if (document) {
        const container = document.getElementById(containerId);
        if (container) {
            const length = container.children.length;

            const { placementInstructions } = getConfig();

            const listInstruction = placementInstructions.find(
                (instruction) => instruction.type === 'list',
            );

            let adPos = 0;
            listInstruction.intervals.forEach(({ interval, placementId }) => {
                adPos += interval;
                if (length > adPos) {
                    renderPlacement(placementId, props, container, adPos);
                }
            });
        }
    }
}

export function renderSearchResultBanners(
    containerId: string,
    props: Props,
    delay?: number,
) {
    removeOldBanners();
    if (delay) {
        setTimeout(() => {
            addNewBanners(containerId, props);
        }, 500);
    } else {
        addNewBanners(containerId, props);
    }
}
