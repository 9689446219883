import 'allsettled-polyfill';
import { initialize as initializeFeed } from '@finn-no/feed';
import { writeExperimentData } from '@finn-no/unleash-cookie/unleash-cookie.js';

import { debouncedLoadTags, pushToAnqSafe } from './podlet/ast';
import { HorseshoeConfig } from '../shared/types/podlet';
import {
    verticalSpecificContentMarketing,
    verticalSpesificInit,
} from './podlet/vertical';
import { Apntag, BannerGlobal, Finn } from '../shared/types/ast';
import { requestBanners } from './utils/request-banners';
import { renderHorseshoe } from './components/horseshoe/horseshoe-render';
import { renderSkyscraper } from './components/skyscraper/skyscraper-render';

import { version } from '../shared/version';
import diagnostics from './utils/diagnostics';
import {
    markHorseshoeInitiated,
    markHorseshoeLoaded,
} from './utils/performance';

import { renderBoard } from './components/board/board-render';
import { renderContentBoard } from './components/content-board/content-board-render';
import { loadGlimrIfPermission } from './utils/glimr';
import { persistPPID } from './utils/pulse-ppid';
import { getFilterData, setupSubscriptions } from './refresh-state';
import { displayAdsLog } from './utils/debug';
import { setupRecirNativeAdSubscription } from './components/recirc-native-ad/recirc-native-ad-render';
import { getConfig } from './config';
import { renderLocalTestEnvironment } from './components/localhost/render-localhost-render';
import { renderContentMarketing } from './components/content-marketing/content-marketing-render';
import { setupRecirBannerAdSubscription } from './components/recirc-banner-ad/recirc-banner-ad-render';
import { companionReadyPlacementListener } from './utils/companion-ready-placement-listener';
import { triggerGoogleAfs } from './utils/google-afs';
import { defineAdvtComponent } from './components/web-components/advt-component';
import {
    getClientReady,
    getPageOptsFromState,
    setClientReady,
    updateState,
} from './advt-state';
import { getPageOptsWithClientKeywords } from './utils/page-opts';

window.FINN = window.FINN || ({} as Finn);
window.FINN.banner = window.FINN.banner || ({} as BannerGlobal);
window.FINN.banner.queue = window.FINN.banner.queue || [];
window.FINN.banner.debouncedLoadTags =
    window.FINN.banner.debouncedLoadTags || debouncedLoadTags();

window.apntag = window.apntag || ({} as Apntag);
window.apntag.anq = window.apntag.anq || [];

export function copyQueue() {
    const { queue } = window.FINN.banner;
    const tempQueue = Array.isArray(queue) ? [...queue] : [];

    if (window.apntag) {
        window.FINN.banner.queue = window.apntag.anq;
        const { anq } = window.apntag;
        tempQueue.forEach((element) => anq.push(element));
    }
}

async function loadConfig(config: HorseshoeConfig) {
    setClientReady(!config.waitForClient);

    // Check if object is not empty
    if (
        config?.experimentsAsJson &&
        Object.keys(config?.experimentsAsJson).length !== 0
    ) {
        writeExperimentData(config.experimentsAsJson);
    }

    // persist PPID data
    // Since this is not AWAITED, this will not happen until the next page load probably
    Promise.all([persistPPID(), loadGlimrIfPermission(config)]).then(() => {
        // PPID and Glimr is set
    });

    const { xandr } = config;

    // Client keywords such as glimr and ppid is not set until next page load
    const pageOptsWithClientKeywords = getPageOptsWithClientKeywords();
    const formattedFilterData = getFilterData(config.filter);
    updateState({
        filter: formattedFilterData,
        pageOpts: pageOptsWithClientKeywords,
    });

    if (xandr && xandr.pageOpts) {
        pushToAnqSafe(() => {
            requestBanners(
                window.apntag,
                {
                    ...xandr,
                    pageOpts: getPageOptsFromState(),
                },
                true,
                true,
            );
            if (getClientReady()) {
                displayAdsLog('index.ts: client ready > copy queue');
                copyQueue();
            }
        });

        setupSubscriptions(config);

        initializeFeed();

        const props = {
            deviceType: config.deviceType,
            vertical: config.vertical,
            pageType: config.pageType,
            wideLayout: config.wideLayout || false,
        };

        if (!config.configOnly) {
            if (config.skyscraperOnly) {
                displayAdsLog(`index.ts render skyscraperOnly`);
                renderSkyscraper('horseshoe-root', props);
            } else {
                displayAdsLog(`index.ts render horseshoe`);
                renderHorseshoe('horseshoe-root', props);
            }
        }

        const isObject = config.pageType === 'object';

        if (isObject || config.pageType === 'front') {
            setupRecirNativeAdSubscription();
            setupRecirBannerAdSubscription();
        }
        defineAdvtComponent();

        if (config.pageType === 'result') {
            triggerGoogleAfs();
            setTimeout(() => {
                companionReadyPlacementListener(
                    config.placementInstructionConfigs,
                );
            }, 500);
        }

        if (isObject) {
            let hasBoard1 = config?.placements?.find(
                (placement) => placement?.placementId === 'board_1',
            );
            let hasContentboard = config?.placements?.find(
                (placement) => placement?.placementId === 'contentboard',
            );

            if (hasBoard1) {
                renderBoard('board_1', props);
            }

            if (hasContentboard) {
                renderContentBoard('contentboard', {
                    ...props,
                    placementId: 'contentboard',
                });
            }
            setTimeout(() => {
                companionReadyPlacementListener(
                    config.placementInstructionConfigs,
                );
            }, 500);
        }

        /**
         * Loads native components in local.finn.no:3040
         */
        renderLocalTestEnvironment('localhost-root');

        verticalSpesificInit(config);
        verticalSpecificContentMarketing(config);
    }
}

function initConditionalRender() {
    const config = getConfig();
    const { contentMarketingOnly } = config;
    if (contentMarketingOnly) {
        renderContentMarketing('content-marketing');
    } else {
        initPodlet(config);
    }
}

function initPodlet(config: HorseshoeConfig) {
    displayAdsLog('initPodlet');
    markHorseshoeInitiated();

    if (config.version === version) {
        diagnostics.groupCollapsed(
            'displayads-podlet-horseshoe loading version: %s ',
            version,
        );
        diagnostics(config);
        diagnostics.groupEnd();
    } else {
        diagnostics(
            `Server version (${config.version}) does not match client version (${version})`,
        );
    }

    loadConfig(config);
}

markHorseshoeLoaded();

displayAdsLog('index.ts: end of file');

let isInitialized = false;
if (document.readyState === 'complete') {
    initConditionalRender();
    isInitialized = true;
}
window.addEventListener('load', () => {
    if (!isInitialized) {
        initConditionalRender();
        isInitialized = true;
    }
});
