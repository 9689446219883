import pulse from '@finn-no/pulse-sdk';
import { PersistedPPID } from '../../shared/types/ast';
import { displayAdsLog } from './debug';
import localStorage from './localStorage';

export function getPPID(): PersistedPPID | null {
    displayAdsLog(`Get PPID: ${window?.FINN?.banner?.PPID}`);
    return localStorage.readValue('displayads-ppid');
}

export async function persistPPID(): Promise<PersistedPPID> {
    const data: PersistedPPID = {
        ppid1: undefined,
        ppid2: undefined,
        externalId: undefined,
    };
    try {
        // const schibstedAccountNumber = window.finnUserId || undefined;

        const tracker = await getTracker();

        const res = await getAdvertisingIdentifiers(tracker);

        displayAdsLog(`PPID response: ${JSON.stringify(res)}`);

        if (res?.xandr) {
            data.ppid1 = res.xandr.ppId1;
            data.ppid2 = res.xandr.ppId2;
            data.externalId = res.xandr.adId;
        }

        displayAdsLog(`Persisting PPID: ${JSON.stringify(data)}`);
        // Set the object on window for use in banners.tsx and other potential places
        window.FINN.banner.PPID = data;
        localStorage.persistValue('displayads-ppid', data);
        return data;
    } catch (err) {
        localStorage.persistValue('displayads-ppid', data);
        return data;
    }
}

export function getTracker() {
    return new Promise((resolve) => {
        pulse.getTracker(async (tracker) => {
            resolve(tracker);
        });
    });
}

export function getAdvertisingIdentifiers(tracker): Promise<{
    xandr: {
        ppId1: string;
        ppId2: string;
        adId: string;
    };
}> {
    return new Promise((resolve, reject) => {
        tracker.getAdvertisingIdentifiers().then(resolve).catch(reject);
    });
}
