import { getConfig, getThresholdProximityFromExperiment } from '../config';
import { UNLEASH_FEATURE_NAMES } from '../../shared/unleash/feature-names';
import { PlacementId } from '../../shared/types/ast';

const NO_PROXIMITY_AND_THRESHOLD = {
    proximity: 0,
    threshold: 0.0,
};

type ReturnType = {
    proximity: number;
    threshold: number;
};

function getForResultPage(placementId: PlacementId): ReturnType {
    switch (placementId) {
        case 'topboard':
            return {
                proximity: 0,
                threshold: 0.4,
            };
        case 'skyscraperleft_1':
        case 'skyscraperright_1': {
            return {
                proximity: 0,
                threshold: 0.5,
            };
        }
        case 'board_1':
        case 'netboard_1': {
            return NO_PROXIMITY_AND_THRESHOLD;
        }
        case 'board_2':
        case 'netboard_2':
        case 'board_3':
        case 'netboard_3':
        case 'board_4':
        case 'netboard_4':
        case 'board_5':
        case 'netboard_5': {
            return { proximity: 50, threshold: 0.0 };
        }
        default: {
            return NO_PROXIMITY_AND_THRESHOLD;
        }
    }
}

function getForSectionPage(placementId: PlacementId): ReturnType {
    switch (placementId) {
        case 'topboard':
            return {
                proximity: 0,
                threshold: 0.4,
            };
        case 'skyscraperleft_1':
        case 'skyscraperright_1': {
            return {
                proximity: 0,
                threshold: 0.5,
            };
        }
        default: {
            return NO_PROXIMITY_AND_THRESHOLD;
        }
    }
}

function getForObjectPage(placementId: PlacementId): ReturnType {
    switch (placementId) {
        case 'topboard':
            return {
                proximity: 0,
                threshold: 0.4,
            };
        case 'skyscraperleft_1':
        case 'skyscraperright_1': {
            return {
                proximity: 0,
                threshold: 0.5,
            };
        }
        case 'board_1': {
            return NO_PROXIMITY_AND_THRESHOLD;
        }
        case 'contentboard': {
            return NO_PROXIMITY_AND_THRESHOLD;
        }
        default: {
            return NO_PROXIMITY_AND_THRESHOLD;
        }
    }
}

function getForFrontPage(placementId: PlacementId): ReturnType {
    switch (placementId) {
        case 'topboard':
            return {
                proximity: 0,
                threshold: 0.4,
            };
        case 'skyscraperleft_1':
        case 'skyscraperright_1': {
            return {
                proximity: 0,
                threshold: 0.5,
            };
        }
        default: {
            return NO_PROXIMITY_AND_THRESHOLD;
        }
    }
}

function getExperiment(adSize?: { width: number; height: number }): ReturnType {
    const proximityExperiment320400 = getThresholdProximityFromExperiment(
        UNLEASH_FEATURE_NAMES.proximityExperiment320400,
    );

    const isSize320400 = adSize?.width === 320 && adSize?.height === 400;
    if (isSize320400 && proximityExperiment320400) {
        return proximityExperiment320400;
    } else return null;
}

export function getBannerProximityByPlacementId(
    placementId: PlacementId,
    adSize?: {
        width: number;
        height: number;
    },
): ReturnType {
    const { pageType } = getConfig();

    const experimentThresholdAndProximity = getExperiment(adSize);
    if (experimentThresholdAndProximity) {
        return experimentThresholdAndProximity;
    }

    if (pageType === 'result') {
        return getForResultPage(placementId);
    } else if (pageType === 'object') {
        return getForObjectPage(placementId);
    } else if (pageType === 'front') {
        return getForFrontPage(placementId);
    } else if (pageType === 'section') {
        return getForSectionPage(placementId);
    } else return NO_PROXIMITY_AND_THRESHOLD;
}
