import * as React from 'react';
import {
    DefineTagOptions,
    DeviceType,
    Format,
    Keywords,
    NativeAdObj,
    Size,
} from '../../../shared/types/ast';
import { publish } from '../../podlet/events';
import { ErrorBoundary } from '../error-boundary';
import { displayAdsLog } from '../../utils/debug';
import { getConfig } from '../../config';
import { getPageOptsWithClientKeywords } from '../../utils/page-opts';
import { useAd } from '../../hooks/ad';
import { Native } from '../native/native';
import { useMemo } from 'react';

export function recircIndex(id: string) {
    const lastNumber = /(\d+)(?!.*\d)/;
    const numberAsMatch = id.match(lastNumber);
    if (numberAsMatch) {
        const numberAsString = numberAsMatch[0];
        const parsedNumber = parseInt(numberAsString, 10);
        return parsedNumber > 3 ? 4 : parsedNumber;
    }
    return 0;
}

export function useCustomTag(
    instanceId,
    deviceType,
    keywords,
    pageType,
    allowedFormats: Format[],
): DefineTagOptions {
    displayAdsLog(`Inside Recirc-ad ${allowedFormats?.join()} getCustomTag `);

    const pageOpts = getPageOptsWithClientKeywords();

    const index = recircIndex(instanceId);
    const devicePrefix = bannerDeviceType(deviceType);
    const formatPrefix = deviceType === 'mobile' ? 'board' : 'netboard';
    const categoryKeywords = Object.entries(keywords).reduce(
        (previousValue, [key, value]) => {
            // @ts-ignore
            previousValue[`no-sno-finn-${key}`] = value;
            return previousValue;
        },
        {} as Keywords,
    );

    const sizes: Size[] = useMemo(() => {
        if (allowedFormats.includes('banner')) {
            if (deviceType === 'mobile') {
                return [
                    [300, 250],
                    [320, 250],
                ];
            } else {
                return [
                    [580, 400],
                    [580, 500],
                ];
            }
        } else {
            return [[1, 1]];
        }
    }, [deviceType, allowedFormats]);

    return {
        invCode: `no-finn-${devicePrefix}-${pageType}_recirc_${index}`,
        sizes,
        allowedFormats,
        targetId: instanceId,
        keywords: {
            'no-sno-finn-section': ['bap'],
            'no-sno-finn-subsection': ['bap_forsale'],
            'no-sno-adformat': [`${formatPrefix}_recirc`],
            ...categoryKeywords,
        },
        disablePsa: true,
        member: pageOpts?.member || 9700,
    };
}
const bannerDeviceType = (deviceType: DeviceType) => {
    switch (deviceType) {
        case 'desktop':
            return 'wde';
        case 'tablet':
            return 'wtb';
        case 'mobile':
            return 'wph';
    }
};

export type Props = {
    instanceId: string;
    keywords: Keywords;
};

const RecircNativeAdReact = ({ instanceId, keywords }: Props) => {
    const { pageType, deviceType } = getConfig();
    const customTag = useCustomTag(instanceId, deviceType, keywords, pageType, [
        'native',
    ]);

    const { adObj, status, targetId } = useAd(instanceId, false, customTag);

    React.useEffect(() => {
        if (status === 'failed') {
            publish('recirc-native-ad', 'load-failed', true);
        }
    }, [status]);

    if (status === 'failed') return null;

    if (!adObj) return null;
    else if (pageType === 'front') {
        return (
            <native-web-component
                adObj={JSON.stringify(adObj) as unknown as NativeAdObj}
                status={status}
                id={targetId}
                grid={true}
                deviceType={deviceType}
                isRecirc={true}
            />
        );
    } else {
        return (
            <Native
                adObj={adObj as NativeAdObj}
                status={status}
                id={targetId}
                grid={true}
                deviceType={deviceType}
                isRecirc={true}
            />
        );
    }
};

const RecircNativeAdReactWithErrorBoundary = (props: Props) => {
    return (
        <ErrorBoundary fallback={null}>
            <RecircNativeAdReact {...props} />
        </ErrorBoundary>
    );
};
export default RecircNativeAdReactWithErrorBoundary;
