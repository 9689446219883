import { renderBoard } from '../../components/board/board-render';
import { HorseshoeConfig } from '../../../shared/types/podlet';

export function handleCurrency(config: HorseshoeConfig) {
    const container = document.getElementById('board_1');
    if (container) {
        container.id = 'board_1_wrapper';
    }
    renderBoard('board_1_wrapper', {
        deviceType: config.deviceType,
        vertical: 'travel',
        subvertical: 'currency',
    });
}
