import { Filter, HorseshoeConfig } from '../shared/types/podlet';
import { ADVT, BannerGlobal, Finn } from '../shared/types/ast';
import {
    markBannerRefresh,
    markFilterUpdated,
    markPageLoaded,
} from './utils/performance';
import { publish, subscribe } from './podlet/events';
import { Feed, ReklameConfiguration } from '../shared/types/reklameMaster';
import { filterKeywordsLegacy } from '../shared/filter';
import { keyValuesEqual, nrmFeedToString } from '../shared/utils';
import { isEmpty } from '../shared/utils';
import { getSearchReklameConfig } from './services/get-search-config/get-search-config';
import { displayAdsLog } from './utils/debug';
import { preProcessTravelFilter } from '../shared/query-requests';
import { refreshApntag, setPageOpts } from './podlet/ast';
import { copyQueue } from './index';
import { verticalSpesificUpdate } from './podlet/vertical';
import { triggerGoogleAfs } from './utils/google-afs';
import * as state from './advt-state';

window.HORSESHOE_PODLET = window.HORSESHOE_PODLET || ({} as ADVT);

/*
Legacy handling of command based event
 */
function updateKeywords(filter: Filter, config: HorseshoeConfig) {
    const update = filterKeywordsLegacy(
        config.vertical,
        filter,
        state.getFilter(),
    );
    state.setFilter(filter);

    if (update.keywords) {
        state.setKeywordsInPageOpts(update.keywords);
        setPageOpts(state.getPageOptsFromState());
    }

    if (update.refresh) {
        refreshApntag();
    }
}

function pageChange(existingFilter?: Filter, updateFilter?: Filter) {
    if (updateFilter?.pageType) {
        return true;
    }
    return (
        existingFilter?.page !== updateFilter?.page ||
        existingFilter?.side !== updateFilter?.side
    );
}

function shouldRefresh(vertical: string, keywordDiff = false) {
    switch (vertical) {
        case 'car':
        case 'bap':
            return keywordDiff;
        default:
            return true;
    }
}

function updateFeed(nrmFeed: Feed) {
    const nrmFeedString = nrmFeedToString(nrmFeed);

    window.FINN = window.FINN || ({} as Finn);
    window.FINN.banner = window.FINN.banner || ({} as BannerGlobal);
    window.FINN.banner.feed = nrmFeed;
    window.FINN.banner.feedString = nrmFeedString;
}

async function getSearchReklameConfigFromApi(
    filter: Filter,
    config: HorseshoeConfig,
): Promise<ReklameConfiguration | null> {
    const { baseUrl, deviceType, publicPathname } = config;

    if (isEmpty(filter)) {
        return null;
    } else {
        displayAdsLog(`getSearchReklameConfig() ${JSON.stringify(filter)} `);
        return await getSearchReklameConfig(
            deviceType,
            filter,
            baseUrl
                ? baseUrl
                : `${window.location.origin}${publicPathname}/api`,
        );
    }
}

function configUpdated(updatedConfig: Filter, config: HorseshoeConfig) {
    const keywords = { ...(state.getPageOptsFromState()?.keywords || {}) };
    if (keywords) {
        // Some travel subverticals don't know who they are before the client is loaded
        if (config.vertical && updatedConfig.subvertical) {
            keywords['no-sno-finn-subsection'] = [
                `${config.vertical}_${updatedConfig.subvertical}`,
            ];
        }
        if (updatedConfig.pageType) {
            keywords['aa-sch-page_type'] = [updatedConfig.pageType as string];
        }
        state.setKeywordsInPageOpts(keywords);
    }
}

function refreshBanners() {
    displayAdsLog(`refreshBanners()`);
    if (state.getClientReady()) {
        const currentCycle = state.getCycle();
        const updatedCycle = currentCycle + 1;
        state.setCycle(updatedCycle);
        markBannerRefresh();

        // publish to ad.ts
        publish('banner', 'refresh', updatedCycle);
    } else {
        state.setClientReady(true);
        copyQueue();
    }
}

export function setupSubscriptions(config) {
    subscribe('banner', 'update', (event) => {
        updateKeywords(event.payload as Filter, config);
    });

    subscribe('filter', 'updated', (event) => {
        markFilterUpdated();
        triggerGoogleAfs();
        filterUpdated(event.payload as Filter, config);
    });

    /**
     * Used to set some keywords from published events on load, e.g. on travel:
     *  const payload = {
     *             pageType,
     *             side: page || 1,
     *             destinasjon: {
     *                 by: city || '',
     *                 land: country || '',
     *             },
     *         };
     */
    subscribe('page', 'loaded', (event) => {
        markPageLoaded();
        configUpdated(event.payload as Filter, config);
        filterUpdated(event.payload as Filter, config);
    });
}

/**
 * This is the only function called when a filter is updated
 * @param updatedFilter
 * @param config
 */
async function filterUpdated(updatedFilter: Filter, config: HorseshoeConfig) {
    const { vertical, subvertical, pageType } = config;

    verticalSpesificUpdate(vertical, subvertical);

    displayAdsLog(
        `filter before verticalSpesificPreProccessing() ${JSON.stringify(
            updatedFilter,
        )}`,
    );
    const filter = {
        ...preProcessTravelFilter(updatedFilter, vertical, subvertical),
        pageType,
    };
    displayAdsLog(
        `filter after verticalSpesificPreProccessing() ${JSON.stringify(
            filter,
        )}`,
    );

    const reklameConfig = await getSearchReklameConfigFromApi(filter, config);

    const isPageChange = pageChange(state.getFilter(), filter);
    let refresh = isPageChange;
    let nrmFeed = window.FINN.banner.feed as Feed;

    if (reklameConfig) {
        const fetchedKeywords = reklameConfig?.keywords || {};
        const keywordDiff = !keyValuesEqual(
            fetchedKeywords,
            state.getInitialKeywords(),
        );
        const alwaysRefresh = shouldRefresh(vertical, keywordDiff);
        refresh = alwaysRefresh || isPageChange;

        state.setFilter(filter);

        if (reklameConfig?.feed) {
            nrmFeed = reklameConfig?.feed;
        }

        state.setKeywordsInPageOpts(fetchedKeywords);
    } else {
        displayAdsLog(`reklameConfig is undefined`);
    }

    if (refresh) {
        setPageOpts(state.getPageOptsFromState(), () => {
            displayAdsLog(`refreshBanners`);
            refreshBanners();
        });
    }

    updateFeed(nrmFeed);
}
export function getFilterData(filterData: Filter) {
    if (filterData) {
        if (filterData.page) {
            filterData.page = Number(filterData.page);
        }
        if (filterData.side) {
            filterData.side = Number(filterData.side);
        }
    }
    return filterData;
}
