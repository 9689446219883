import { getFeatureStatus } from '../config';
import { UNLEASH_FEATURE_NAMES } from '../../shared/unleash/feature-names';
import { PersistedPPID } from '../../shared/types/ast';
import { displayAdsLog } from './debug';
import { getPPID } from './pulse-ppid';
import { markTagsLoaded } from './performance';
import { getKeywordByKey } from './page-opts';

export function loadTagsWithPrebid(apntag, params = {}) {
    const enableRelevantDigitalAd = getFeatureStatus(
        UNLEASH_FEATURE_NAMES.enableRelevantDigitalAd,
    );

    if (!enableRelevantDigitalAd) {
        apntag.loadTags();
    } else {
        if (typeof window !== 'undefined') {
            window.relevantDigital = window.relevantDigital || {};
            window.relevantDigital.cmd = window.relevantDigital.cmd || [];

            let cancel; // if true => we've timed out and and "emergency-call" to apntag.loadTags() has already been done
            const events: any = {
                relevantCb: { timeout: 1000 }, // Wait up to 1 second for the Relevant script
                auctionCb: { timeout: 2000 }, // Wait up to 2 seconds for the auction to complete
            };

            const failsafeOnTimeout = (ev) =>
                setTimeout(() => {
                    if (!events[ev].done) {
                        console.log(
                            `Doing failsafe loadTags() call after waiting too long for '${ev}'`,
                        );
                        cancel = true;
                        apntag.loadTags();
                    }
                }, events[ev].timeout);

            failsafeOnTimeout('relevantCb');

            window.relevantDigital.cmd.push(async function () {
                if (cancel) {
                    // We were too slow -  do nothing
                    return;
                }
                events.relevantCb.done = true;
                failsafeOnTimeout('auctionCb');

                window.relevantDigital.addPrebidConfig({
                    consentManagement: { cmpApi: 'none' }, // No IAB CMP
                    // s2sConfig: { syncEndpoint: null }, // Don't use PBS cookie syncing
                    enableSendAllBids: false, // Only send key-values for *winning* bids (HB_PB etc)
                    userSync: getStoredPpid(),
                    ortb2: getOrtb2Data(),
                });
                window.relevantDigital.loadPrebid({
                    configId: '6321fd9761ced1674e713277',
                    manageAdserver: false,
                    loadUnknownSlots: true, // Load placements/tags not added in Yield
                    noSlotReload: true, // Don't reload placements that has already been loaded
                    noGpt: true, // Don't load gpt.js as it's done above
                    apntagCalls: {
                        showTag: () => {
                            /** Skip, we're doing this ourselves */
                        },
                        loadTags: (...args) => {
                            if (!cancel) {
                                if (window.apntag && window.apntag.anq) {
                                    window.apntag.anq.push(() => {
                                        if (window.apntag) {
                                            events.auctionCb.done = true;
                                            displayAdsLog(
                                                `loadTags(args = ${JSON.stringify(
                                                    args,
                                                )}) FROM relevant-digital-load-ads.ts`,
                                            );
                                            markTagsLoaded();
                                            const foundArgs = args?.[0];
                                            if (foundArgs?.length > 0) {
                                                apntag.loadTags(args[0]);
                                            }
                                        }
                                    });
                                }
                            } // else => We were too slow - do nothing
                        },
                    },
                    ...params,
                });
            });
        }
    }
}

function getOrtb2Data() {
    const sectionData = getKeywordByKey('no-sno-finn-section');
    const subsectionData = getKeywordByKey('no-sno-finn-subsection');

    if (sectionData || subsectionData) {
        return {
            site: {
                ext: {
                    data: {
                        'no-sno-finn-section': sectionData,
                        'no-sno-finn-subsection': subsectionData,
                    },
                },
            },
        };
    } else return undefined;
}
function getStoredPpid() {
    const persistedPPID: PersistedPPID = getPPID();

    if (!persistedPPID?.ppid2) {
        return {};
    }
    return getFormattedUserIdObject(persistedPPID);
}

function getFormattedUserIdObject(persistedPPID: PersistedPPID) {
    const eids = [];

    if (persistedPPID.ppid1) {
        eids.push({
            source: 'SCHNO-UserHash',
            uids: [
                {
                    id: persistedPPID.ppid1, // PPID1
                    atype: 1,
                    ext: {
                        stype: 'ppuid',
                    },
                },
            ],
        });
    }

    if (persistedPPID.ppid2) {
        eids.push({
            source: 'SCHNO-EnvHash',
            uids: [
                {
                    id: persistedPPID.ppid2, // PPID2
                    atype: 1,
                    ext: {
                        stype: 'ppuid',
                    },
                },
            ],
        });
    }
    return {
        userIds: [
            {
                name: 'pubProvidedId',
                params: {
                    eids,
                },
            },
        ],
    };
}
