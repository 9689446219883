import * as React from 'react';
import { render } from 'react-dom';
import ContentMarketingContainer from './content-marketing-container';
import { PageType } from '../../../shared/types/ast';

export function renderContentMarketing(
    id: string,
    pageType?: PageType,
    callback?: () => void,
) {
    if (document) {
        const container = document.getElementById(id + '-container');
        if (container) {
            container.classList.add('advt-placement');
            render(
                <ContentMarketingContainer id={id} pageType={pageType} />,
                container,
                callback,
            );
        }
    }
}
