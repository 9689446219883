import { subscribe } from '../../podlet/events';
import { render } from 'react-dom';
import * as React from 'react';
import RecircNativeAdReact, { Props } from './recirc-native-ad';
import {
    addSButtonSlotToRecommendation,
    addSlotToRecommendation,
} from '../web-components/utils';
import { displayAdsLog } from '../../utils/debug';

export function setupRecirNativeAdSubscription() {
    displayAdsLog(`setupRecirNativeAdSubscription`);
    subscribe('recirc-native-ad', 'init', renderRecircNativeAd);
}

function renderRecircNativeAd(event) {
    const props = event.payload as Props;
    const { instanceId } = props;

    if (document) {
        addSlotToRecommendation(instanceId);
        addSButtonSlotToRecommendation(instanceId);
        const container = document.getElementById(instanceId);
        if (container) {
            container.classList.add('advt-placement');
            render(<RecircNativeAdReact {...props} />, container);
        }
    }
}
