import { getConfig } from '../config';

export const handleAdNotLoaded = async (
    targetId: string,
    reason: string,
    data?: any,
) => {
    const config = getConfig();
    await fetch(config.baseUrl + `/banner-not-loaded?id=${targetId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            reason,
            targetId,
            vertical: config.vertical,
            subvertical: config.subvertical,
            pageType: config.pageType,
            deviceType: config.deviceType,
            data: data || undefined,
        }),
    }).then((response) => response.json());
};
