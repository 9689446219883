import * as React from 'react';
import { useEffect } from 'react';
import { Size, DeviceType } from '../../shared/types/ast';
import Logo from './logo';
import { useFallback } from '../hooks/fallback';
import { displayAdsLog } from '../utils/debug';

type Props = {
    id: string;
    deviceType: DeviceType;
};

function fallbackSize(id: string, deviceType: DeviceType): Size | undefined {
    switch (id) {
        case 'topboard':
            switch (deviceType) {
                case 'desktop':
                    return [1010, 150];
                case 'tablet':
                    return [768, 150];
                case 'mobile':
                    return undefined;
                default:
                    return undefined;
            }
        case 'skyscraperright_1':
            switch (deviceType) {
                case 'desktop':
                    return [180, 700];
                case 'tablet':
                case 'mobile':
                    return undefined;
                default:
                    return undefined;
            }
        case 'wallpaper':
            switch (deviceType) {
                case 'desktop':
                    return [2, 2];

                case 'tablet':
                case 'mobile':
                    return undefined;
                default:
                    return undefined;
            }
        case 'skyscraperleft_1':
            switch (deviceType) {
                case 'desktop':
                    return [180, 701];
                case 'tablet':
                case 'mobile':
                    return undefined;
                default:
                    return undefined;
            }
        case 'contentboard':
            return [1, 1];

        default:
            switch (deviceType) {
                case 'mobile':
                    return [300, 250];

                case 'tablet':
                case 'desktop':
                    return [580, 400];
                default:
                    return undefined;
            }
    }
}

const primary = '#0063FB';
const secondary = '#06BEFB';
const licorice = '#474445';
const stone = '#767676';
const cherry = '#D9270A';
const pea = '#2EE69F';
const sardine = '#C3CCD9';
const ice = '#F1F9FF';
const mint = '#CCFFEC';
const banana = '#FFF5CB';
const salmon = '#FFEFEF';
const milk = '#FFFFFF';
const marble = '#F6F8FB';
const watermelon = '#FF5844';

const ripe = '#FFE470';

type Colors = {
    dark: string;
    light: string;
    text: string;
    top: string;
    bottom: string;
    stripes?: string;
};

const finn = `repeating-linear-gradient(45deg,
                ${mint} 0px 60px,
                ${banana} 60px 120px,
                ${salmon} 120px 180px,
                ${watermelon} 180px 240px,
                ${primary} 240px 300px,
                ${secondary} 300px 360px)`;

export function fallbackColors(): Colors {
    const bucket = Math.floor(new Date().getMinutes() / 10);
    switch (bucket) {
        case 0:
            return {
                dark: primary,
                light: secondary,
                text: milk,
                top: secondary,
                bottom: milk,
            };
        case 1:
            return {
                dark: licorice,
                light: stone,
                text: milk,
                top: sardine,
                bottom: milk,
                stripes: finn,
            };
        case 2:
            return {
                dark: sardine,
                light: ice,
                text: stone,
                top: ice,
                bottom: milk,
            };
        case 3:
            return {
                dark: ripe,
                light: banana,
                text: milk,
                top: banana,
                bottom: milk,
            };
        case 4:
            return {
                dark: cherry,
                light: watermelon,
                text: milk,
                top: salmon,
                bottom: marble,
            };
        case 5:
            return {
                dark: pea,
                light: mint,
                text: milk,
                top: mint,
                bottom: milk,
            };

        default:
            return {
                dark: primary,
                light: secondary,
                text: milk,
                top: secondary,
                bottom: milk,
            };
    }
}

export function Fallback(props: Props) {
    const { id, deviceType } = props;
    displayAdsLog(`RENDER fallback.tsx FOR ${id}`);

    const fallback = useFallback();
    const size = fallbackSize(id, deviceType);
    const colors = fallbackColors();
    const takeover = id === 'board_2';

    useEffect(() => {
        if (document) {
            if (fallback && takeover) {
                document.body.classList.add('mobile', 'has-ad-takeover');
            }
        }
        return function cleanup() {
            if (document && fallback && takeover) {
                document.body.classList.remove('mobile', 'has-ad-takeover');
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // We only want this to run once

    if (fallback && size) {
        const [, height] = size;

        const stripes =
            colors.stripes ||
            `repeating-linear-gradient(45deg, transparent 0 24px, ${milk} 24px 30px)`;

        const fallbackStyle = {
            opacity: 0,
            width: '100%',
            height: `${height}px`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundAttachment: 'fixed',
            backgroundImage: `${stripes},linear-gradient(0deg, ${colors.bottom}, ${colors.top}`,
        };

        const takeoverStyle = {
            marginLeft: '-16px',
            marginRight: '-16px',
            height: '100vh',
            width: '100vw',
        };
        return (
            <div
                id={`${id}-fallback`}
                className="fallback"
                style={
                    takeover
                        ? { ...fallbackStyle, ...takeoverStyle }
                        : fallbackStyle
                }
            >
                <Logo
                    dark={colors.dark}
                    light={colors.light}
                    text={colors.text}
                />
            </div>
        );
    }
    return null;
}
