import * as React from 'react';
import { displayAdsLog } from '../utils/debug';

type Props = {
    id: string;
    className?: string;
    children?: React.ReactNode;
};

export function Placeholder(props: Props) {
    const {
        id,
        className = 'grid overflow-hidden relative transition-all outline-none col-span-full',
        children,
    } = props;

    displayAdsLog(`RENDER placeholder.tsx FOR ${id}`);

    return (
        <div id={id} className={className}>
            {children}
        </div>
    );
}
