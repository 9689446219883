import { PermissionValue } from '../../shared/types/cmp';
import { CMPCategory } from '../../shared/types/ast';
import { displayAdsLog } from '../utils/debug';

export function subscribeToConsent(
    category: CMPCategory,
    callback: (permissionValue: PermissionValue) => void,
) {
    window?._tcf_?.getPermission(category, (consentValue) => {
        displayAdsLog({ consentValue });
        callback(consentValue);
    });
}

export function getConsent(category: CMPCategory): PermissionValue {
    return window?._tcf_?.getPermissionSync(category);
}

export function getConsentOrSubscribe(
    onConsentReady: (permissionValue: PermissionValue) => void,
    category: CMPCategory = 'CMP:advertising',
) {
    if (!window?._tcf_) {
        displayAdsLog('TCF not found');
        onConsentReady('1');
        return;
    }
    const consentValueSync = getConsent(category);

    displayAdsLog({ consentValueSync });
    if (typeof consentValueSync === 'string') {
        onConsentReady(consentValueSync);
    } else {
        subscribeToConsent(category, (permissionValue) => {
            onConsentReady(`${permissionValue}`);
        });
    }
}
