import { setupFinnGlobalVar } from './finn';
import { initApnListeners, trackContentboard } from './listeners';
import { Apntag, XandrData } from '../../shared/types/ast';
import { displayAdsLog } from './debug';
import {
    defineTag,
    loadAstTags,
    pushToAnqSafe,
    setPageOpts,
} from '../podlet/ast';

export { initTakeover, injectTakeoverTemplate, isTakeover } from './takeover';

export function requestBanners(
    apntag: Apntag,
    xandrData: XandrData,
    loadTags = true,
    addListeners = true,
) {
    if (apntag && apntag.anq && xandrData) {
        pushToAnqSafe(() => {
            setupFinnGlobalVar(xandrData);

            setPageOpts(xandrData.pageOpts);
            if (loadTags) {
                Object.values(xandrData.tags).forEach(defineTag);
                displayAdsLog('debouncedLoadTagsSafe FROM banners.tsx');
                loadAstTags();
            }
            if (addListeners) {
                initApnListeners(apntag, xandrData);
            }
        });
    }
}

export { initApnListeners, trackContentboard };
