import { subscribe } from '../../podlet/events';
import { render } from 'react-dom';
import * as React from 'react';
import RecircBannerAdReact, { Props } from './recirc-banner-ad';
import { addSlotToRecommendation } from '../web-components/utils';
import { displayAdsLog } from '../../utils/debug';

export const CHANNEL_BANNER_AD = 'recirc-banner-ad';
export function setupRecirBannerAdSubscription() {
    displayAdsLog(`setupRecirBannerAdSubscription`);
    subscribe(CHANNEL_BANNER_AD, 'init', renderRecircBannerAd);
}

function renderRecircBannerAd(event) {
    const props = event.payload as Props;
    const { instanceId } = props;

    if (document) {
        addSlotToRecommendation(instanceId);
        const container = document.getElementById(instanceId);
        if (container) {
            container.classList.add('advt-placement');
            render(<RecircBannerAdReact {...props} />, container);
        }
    }
}
