import { useEffect, useState } from 'react';
import { DeviceType } from '../../shared/types/ast';
import { getConfig } from '../config';

function getDeviceType() {
    if (typeof navigator !== 'undefined') {
        const ua = navigator.userAgent.toLowerCase();
        if (ua.includes('tablet') || ua.includes('ipad')) {
            return 'tablet';
        } else if (ua.includes('mobi') || ua.includes('iphone')) {
            return 'mobile';
        }
    }
    return 'desktop';
}

export function useDeviceType(deviceTypeFromServer?: DeviceType) {
    const config = getConfig();
    const deviceTypeFromConfig = config?.deviceType;

    const [deviceType, setDeviceType] = useState(
        deviceTypeFromServer || deviceTypeFromConfig || 'desktop',
    );

    useEffect(() => {
        function checkDeviceType() {
            if (typeof navigator !== 'undefined') {
                const updatedDeviceType = getDeviceType();
                if (updatedDeviceType !== deviceType) {
                    setDeviceType(updatedDeviceType);
                }
            }
        }
        if (window !== undefined) {
            checkDeviceType();
            window.addEventListener('resize', checkDeviceType);
        }
        return function cleanup() {
            if (window !== undefined) {
                window.removeEventListener('resize', checkDeviceType);
            }
        };
    }, [deviceType]);
    return deviceTypeFromServer || deviceType;
}
