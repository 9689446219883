import {
    PageOpts,
    PersistedPPID,
    PPIDUserObject,
} from '../../shared/types/ast';
import { getPPID } from './pulse-ppid';
import { displayAdsLog } from './debug';
import { PermissionValue } from '../../shared/types/cmp';

/**
 * Set pageOpts:
 * PPID in pageOpts.user.userIds and pageOpts.eids (because doc says to set it on userIds, but it only works if you set it
 * directly on eids.
 *
 * Old solution which we need to keep:
 * pageOpts.user.externalUid: adId (which is basically the same as SCHNO-EnvHash (PPID2)
 * pageOpts.user.dnt = do not track
 * @param pageOpts
 */
export function setExternalUid(pageOpts: PageOpts): PageOpts {
    const persistedPPID: PersistedPPID = getPPID();

    displayAdsLog({ persistedPPID });

    const adId = persistedPPID?.externalId;
    pageOpts.user = {};
    if (hasCMPPermission()) {
        pageOpts.user.externalUid = adId;
    }

    const doNotTrack =
        adId === 'DO_NOT_TRACK' || (navigator && navigator.doNotTrack === '1');
    pageOpts.user.dnt = doNotTrack;

    displayAdsLog({ adId, doNotTrack });

    try {
        if (persistedPPID) {
            // Remove undefined id's
            const extIds = [
                {
                    source: 'SCHNO-UserHash',
                    id: persistedPPID.ppid1,
                } as PPIDUserObject,
                {
                    source: 'SCHNO-EnvHash',
                    id: persistedPPID.ppid2,
                } as PPIDUserObject,
            ];
            const filteredEids = extIds.filter((d) => Boolean(d.id));

            pageOpts.user.userIds = [
                {
                    type: 'extendedIDs',
                    eids: filteredEids,
                },
            ];

            // Also set keywords for v3 request
            const ppidKeywords = {
                'aa-sch-schuserhash': persistedPPID.ppid1 ? ['1'] : ['0'],
                'aa-sch-schenvhash': persistedPPID.ppid2 ? ['1'] : ['0'],
            };

            pageOpts.keywords = {
                ...pageOpts.keywords,
                ...ppidKeywords,
            };

            displayAdsLog(
                `pageOpts.user.userIds = ${JSON.stringify(
                    pageOpts.user.userIds,
                )}`,
            );
        }
    } catch (e) {
        console.error(e);
    }

    return pageOpts;
}

export function getCMPAdvertisingFromTcf() {
    if (typeof window !== 'undefined') {
        if (window._tcf_ && window._tcf_.getPermissionSync) {
            return window._tcf_.getPermissionSync('CMP:advertising');
        } else {
            displayAdsLog('TCF not found. Default to 1');
            return '1';
        }
    } else {
        return null;
    }
}
export function hasCMPPermission(): boolean {
    const permission = getCMPAdvertisingFromTcf();
    displayAdsLog(`CMP permission: ${permission}`);
    return `${permission}` === '1';
}
export function getPageOptsWithConsentKeyword(
    pageOpts: PageOpts,
    consentStatus: PermissionValue,
): PageOpts | undefined {
    return {
        ...pageOpts,
        keywords: {
            ...pageOpts.keywords,
            'aa-sch-cmp_advertising': [
                consentStatus === null ? '0' : consentStatus,
            ],
        },
    };
}
