import { XandrData } from '../../shared/types/ast';
import { Finn, BannerGlobal } from '../../shared/types/ast';

export function setupFinnGlobalVar(xandrData: XandrData) {
    window.FINN = window.FINN || ({} as Finn);
    window.FINN.banner = window.FINN.banner || ({} as BannerGlobal);
    window.FINN.banner.feed = xandrData.feed;
    window.FINN.banner.feedString = xandrData.feedString;
    window.FINN.banner.ssa = {};
    window.FINN.banner.ads = {};
    window.FINN.banner.override = '';
}

export function updateFinnGlobalVar(creativeIds: { [key: string]: number }) {
    const overrideString = Object.keys(creativeIds).reduce(
        (result, key) => `${key}:${creativeIds[key]},${result}`,
        '',
    );
    window.FINN = window.FINN || ({} as Finn);
    window.FINN.banner = window.FINN.banner || ({} as BannerGlobal);
    window.FINN.banner.ads = creativeIds;
    window.FINN.banner.override = `ast_override_div=${overrideString}`;
}
