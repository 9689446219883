// ad-component.ts
import { css, html, LitElement, PropertyValues } from 'lit';

import { events, messageBus } from '@schibsted-nmp/advertising-events';

import { Props } from '../ad-switch/switch';
import { renderPlacement } from '../placement';
import { getType } from '../../utils/companion-ready-placement-listener';

const NAME = 'advt-component';
class AdvtComponent extends LitElement implements Props {
    // Properties with default values or required indicators
    containerId: string;
    placementId: string;
    adIndex?: number;
    cols?: 1 | 2 | 3;
    additionalData?: object;
    loading: boolean = true;

    static get properties() {
        return {
            containerId: { type: String },
            placementId: { type: String },
            adIndex: { type: Number },
            cols: { type: Number },
            additionalData: { type: Object },
        };
    }

    unsubscribeActiveSubscription: () => void = () => {};

    setupStylesSubscription() {
        this.unsubscribeActiveSubscription = messageBus.subscribe(
            events.PODLET.channel,
            events.PODLET.AD_STYLES.topic,
            (event) => {
                const payload = events.PODLET.AD_STYLES.getPayload(event);
                if (payload.containerId === this.containerId) {
                    // Apply styles and classes to the host element
                    this.className = payload.className;
                }
            },
        );
    }

    constructor() {
        super();
        this.placementId = '';
        this.containerId = '';
        this.adIndex = 0;
        this.cols = 1;
        this.additionalData = {};

        this.setupStylesSubscription();
    }

    // unmount subscription
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    private makeSlotAvailableFromLightDOM(placementId: string) {
        const containerId = `${placementId}--container`;

        if (this.querySelector(`#${containerId}`)) {
            // If the container is already in the surrounding DOM, do nothing. This
            // check makes the component backwards compatible when the slotted
            // container is added by the component user.
            //
            // This is necessary because the component is used in two ways:
            // 1. The component user adds the container to the light DOM. This is the
            //    recommended way of using the component. One reason for this is to avoid
            //    hydration mismatch issues when using the component with React SSR.
            // 2. The component user does not add the container to the light DOM.
            //    This is the legacy way of using the component. The component adds the
            //    container to the light DOM itself, in order to be backwards compatible.
            return;
        }

        const slotDiv = document.createElement('div');
        slotDiv.slot = `${placementId}--slot`;
        slotDiv.id = containerId;

        // add the div with slot available in the light DOM
        this.appendChild(slotDiv);
    }
    protected firstUpdated(_changedProperties: PropertyValues) {
        super.firstUpdated(_changedProperties);
        this.containerId = this.containerId || `${this.placementId}--container`;

        this.makeSlotAvailableFromLightDOM(this.placementId);
        renderPlacement(this.containerId, {
            id: this.placementId,
            grid: this.cols !== 1,
            showBanners: true,
            type: getType(this.adIndex),
        });
    }

    static styles = css`
        :host {
            display: none;
            position: relative;
            z-index: 2;
            grid-column: 1 / -1;
        }

        :host([placementid*='board_']) {
            margin: 0 auto;
            width: 100%;
        }

        :host(.native-ad[placementid*='board_']) {
            grid-column: unset;
        }

        :host(.loaded) {
            display: block;
        }
        :host(.failed) {
            display: none;
        }
    `;

    render() {
        return html` <slot
            name="${`${this.placementId}--slot`}"
            id="${this.containerId || `${this.placementId}--container`}"
        ></slot>`;
    }
}

export function defineAdvtComponent() {
    if (!customElements.get(NAME)) {
        customElements.define(NAME, AdvtComponent);
    }
}
