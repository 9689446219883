import * as React from 'react';
import { useEffect } from 'react';
import { AdTakeover } from '@sch-inventory/ad-takeover/dist/ad-takeover';
import { useShowTag } from '../hooks/ad';
import { getConfig } from '../config';

type Props = {
    id: string;
    targetId: string;
    className?: string;
    proximity?: number;
    slotAd?: boolean;
};

const TakeoverAd = ({ slotAd, children, id, className }) =>
    slotAd ? (
        <slot name={`${id}_slot`} className={className} id={id}>
            {children}
        </slot>
    ) : (
        <div className={className} id={id}>
            {children}
        </div>
    );

export function Takeover(props: Props) {
    const {
        targetId,
        slotAd,
        className = 'relative col-span-full',
        proximity = 100,
    } = props;

    // set initial toggle with state

    useShowTag(targetId, proximity);
    useEffect(() => {
        if (document) {
            try {
                const width = Math.max(
                    document.documentElement.clientWidth,
                    window.innerWidth || 0,
                );

                if (window.apntag) {
                    AdTakeover.create(
                        targetId,
                        {
                            articlesContainerWidth: width,
                            headerSelector: '.top-bar',
                        },
                        window.apntag,
                    );
                    if (getConfig()?.deviceType === 'mobile') {
                        const body = document.getElementsByTagName('body')[0];
                        if (body) body.classList.add('mobile');
                    }
                }
            } catch (ex) {
                console.error(ex);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // We only want this to run once

    return (
        <TakeoverAd slotAd={slotAd} id={targetId} className={className}>
            <span className="">
                <div
                    id={`${targetId}-button`}
                    className="ad-info"
                    style={{ left: -16 }}
                />
            </span>
        </TakeoverAd>
    );
}
