import axios from 'axios';
import type { ReklameConfiguration } from '../../../shared/types/reklameMaster';
import type { DeviceType } from '../../../shared/types/ast';
import type { Filter } from '../../../shared/types/podlet';

export async function getSearchReklameConfig(
    device: DeviceType,
    searchFilters: Filter,
    baseUrl: string,
): Promise<ReklameConfiguration> {
    const url = `${baseUrl}/search-config`;
    try {
        const { search_key, ...search_filters } = searchFilters;
        const body = {
            platform: 'web',
            device: device === 'mobile' ? 'phone' : device,
            search_key,
            search_filters: search_filters || {},
        };

        const { data } = await axios.post(url, body);
        return data as ReklameConfiguration;
    } catch (e) {
        console.error(`Unable to get reklame configuration - error: ${e}`);
    }
}
