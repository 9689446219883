import * as React from 'react';
import { BannerAdObj, Keywords } from '../../../shared/types/ast';
import { ErrorBoundary } from '../error-boundary';
import { getConfig } from '../../config';
import { useCustomTag } from '../recirc-native-ad/recirc-native-ad';
import { publish } from '../../podlet/events';
import { Banner } from '../banner';
import { useAd } from '../../hooks/ad';

export type Props = {
    instanceId: string;
    keywords: Keywords;
};

const RecircBannerAdReact = ({ instanceId, keywords }: Props) => {
    const { pageType, deviceType, vertical } = getConfig();
    const customTag = useCustomTag(instanceId, deviceType, keywords, pageType, [
        'banner',
    ]);
    const { adObj, status, targetId } = useAd(instanceId, false, customTag);

    React.useEffect(() => {
        if (status === 'failed') {
            publish('recirc-banner-ad', 'load-failed', true);
        }
    }, [status]);

    const _adObj = adObj as BannerAdObj;

    if (status === 'failed') return null;
    else if (_adObj?.adType !== 'banner') return null;
    return (
        <Banner
            slotAd={true}
            id={instanceId}
            grid={false}
            targetId={targetId}
            pageType={pageType}
            vertical={vertical}
            adSize={
                _adObj?.height && _adObj?.width
                    ? {
                          height: _adObj.height,
                          width: _adObj.width,
                      }
                    : null
            }
        />
    );
};

const RecircBannerAdReactWithErrorBoundary = (props: Props) => {
    return (
        <ErrorBoundary fallback={null}>
            <RecircBannerAdReact {...props} />
        </ErrorBoundary>
    );
};
export default RecircBannerAdReactWithErrorBoundary;
