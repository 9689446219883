import * as React from 'react';
import { useDeviceType } from '../../hooks/deviceType';
import {
    DeviceType,
    PageType,
    Vertical,
    Subvertical,
} from '../../../shared/types/ast';
import { Switch } from '../ad-switch/switch';
import { ErrorBoundary } from '../error-boundary';
import { bannerName } from '../../utils/placement-instruction';

export type Props = {
    deviceType?: DeviceType;
    vertical?: Vertical;
    subvertical?: Subvertical;
    pageType?: PageType;
};

function SwitchBoard(props: Props) {
    const {
        vertical,
        subvertical,
        pageType,
        deviceType: deviceTypeFromServer,
    } = props;
    const deviceType = useDeviceType(deviceTypeFromServer);
    const id = bannerName(deviceType, 1);

    if (
        (vertical === 'travel' && subvertical === 'prepackage') ||
        pageType === 'object'
    ) {
        return (
            <Switch
                placementId={id}
                className=""
                {...props}
                deviceType={deviceType}
                pageType={pageType}
            />
        );
    }

    if (deviceType === 'mobile') {
        let className = 'banners p-0 mx-16';
        if (subvertical === 'currency') {
            className = 'p-0';
        }

        return (
            <Switch
                placementId={id}
                className={className}
                {...props}
                deviceType={deviceType}
                pageType={pageType}
            />
        );
    }
    return null;
}

const SwitchBoardWithErrorBoundary = (props: Props) => {
    return (
        <ErrorBoundary fallback={null}>
            <SwitchBoard {...props} />
        </ErrorBoundary>
    );
};

export default SwitchBoardWithErrorBoundary;
