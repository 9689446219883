import { Apntag, XandrData } from '../../shared/types/ast';
import { initTakeover } from './takeover';
import { inscreen } from './lazy';
import { displayAdsLog } from './debug';

function clearWallpaper() {
    const body = document.body;
    if (body) {
        body.style.cssText = '';
    }
}

const tracker = (apntag: Apntag, adObj: any) => {
    const loadElement = (ele: Element) => {
        const tagId = ele.getAttribute('id');
        if (tagId) {
            apntag.anq.push(() => apntag.showTag(tagId));
        }
    };

    return () => {
        const element = document.getElementById(adObj.targetId);
        if (element) {
            inscreen(element, loadElement);
        }
        if (adObj.targetId) {
            apntag.anq.push(() =>
                apntag.attachClickTrackers(adObj, adObj.targetId),
            );
        }
    };
};

export function initApnListeners(apntag: Apntag, xandrData: XandrData) {
    displayAdsLog('initApnListeners');
    if (apntag && apntag.anq) {
        apntag.anq.push(() => {
            const tags = Object.keys(xandrData.tags);

            // ****** adLoaded --> log inview
            tags.forEach((tagId) => {
                if (tagId !== 'wallpaper') {
                    apntag.onEvent('adLoaded', tagId, (adObj) => {
                        if (
                            tagId === 'contentboard' &&
                            adObj.source === 'rtb' &&
                            adObj.adType === 'native' &&
                            adObj.native
                        ) {
                            apntag.fireImpressionTrackers(adObj);
                        }
                        apntag.offEvent('adLoaded', tagId);
                    });
                }
            });

            // ****** adNoBid --> clearWallpaper
            apntag.onEvent('adNoBid', 'wallpaper', clearWallpaper);

            initTakeover(apntag);
        });
    }
}

export function trackContentboard(apntag: Apntag, adObj: any) {
    if (apntag && apntag.anq) {
        apntag.anq.push(() => {
            // ****** temporarily we only track contentboard directly
            if (
                adObj.source === 'rtb' &&
                adObj.adType === 'native' &&
                adObj.native
            ) {
                setTimeout(tracker(apntag, adObj), 100);
            }
        });
    }
}
