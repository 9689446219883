import * as React from 'react';
import { render } from 'react-dom';
import { Props, Switch } from '../ad-switch/switch';

export function renderContentBoard(
    id: string,
    props: Props,
    callback?: () => void,
) {
    if (document) {
        const container = document.getElementById(id);
        if (container) {
            container.classList.add('advt-placement');
            render(<Switch {...props} placementId={id} />, container, callback);
        }
    }
}
