import { useMemo } from 'react';
import { getConfig } from '../config';
import type { PlacementInstruction } from '../../shared/types/reklameMaster';
import { DeviceType } from '../../shared/types/ast';

const { placementInstructions } = getConfig();

export const bannerName = (deviceType: DeviceType, index: number) =>
    `${deviceType === 'mobile' ? 'board' : 'netboard'}_${index}`;

const getServerPlacementInstructions = (
    deviceType: DeviceType,
): PlacementInstruction[] => [
    {
        type: 'grid',
        intervals: [
            {
                placementId: bannerName(deviceType, 1),
                interval: 6,
            },
            {
                placementId: bannerName(deviceType, 2),
                interval: 6,
            },
            {
                placementId: bannerName(deviceType, 3),
                interval: 12,
            },
            {
                placementId: bannerName(deviceType, 4),
                interval: 6,
            },
            {
                placementId: bannerName(deviceType, 5),
                interval: 6,
            },
        ],
    },
    {
        type: 'list',
        intervals: [
            {
                placementId: bannerName(deviceType, 1),
                interval: 6,
            },
            {
                placementId: bannerName(deviceType, 2),
                interval: 6,
            },
            {
                placementId: bannerName(deviceType, 3),
                interval: 12,
            },
            {
                placementId: bannerName(deviceType, 4),
                interval: 6,
            },
            {
                placementId: bannerName(deviceType, 5),
                interval: 6,
            },
        ],
    },
];

type PositionData = {
    placementId: string;
    position: number;
    advtIndex: number;
};
export const useSearchPositionInstruction = (
    cols,
    deviceType,
): PositionData[] => {
    const bannerLayout: PlacementInstruction = useMemo(() => {
        const instructions: PlacementInstruction[] =
            placementInstructions || getServerPlacementInstructions(deviceType);
        return instructions?.find(
            (ins) => ins.type === (cols === 1 ? 'list' : 'grid'),
        );
    }, [cols, deviceType]);

    return useMemo(() => {
        let position = 0;
        return bannerLayout?.intervals.map(
            ({ placementId, interval }, index) => {
                // Create new element
                position += interval;

                return {
                    placementId,
                    position,
                    advtIndex: index + 1,
                };
            },
        );
    }, [bannerLayout?.intervals]);
};
