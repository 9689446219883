import { renderSearchResultBanners } from '../../components/search-result/search-result-banners-render';

export function handleLastMinute(delay?: number) {
    renderSearchResultBanners(
        'lmsResults',
        {
            showBanners: true,
            id: 'temp',
            grid: false,
            type: 'ast',
        },
        delay,
    );
}
