import * as React from 'react';
import {
    DeviceType,
    PageType,
    AdObj,
    Size,
    Vertical,
    Subvertical,
    BannerAdObj,
    PlacementId,
} from '../../../shared/types/ast';
import { getSizeFromAdObj, useAd } from '../../hooks/ad';
import { Wallpaper } from '../wallpaper';
import { Placeholder } from '../placeholder';
import { Banner } from '../banner';
import { ErrorBoundary } from '../error-boundary';
import { displayAdsLog } from '../../utils/debug';
import { useEffect, useMemo } from 'react';
import { getConfig } from '../../config';
import throttle from 'lodash.throttle';

export type Props = {
    deviceType: DeviceType;
    vertical?: Vertical;
    subvertical?: Subvertical;
    pageType?: PageType;
    wideLayout?: boolean;
    slotAd?: boolean;
};

function Horseshoe(props: Props) {
    displayAdsLog(`Render Horseshoe`);

    const deviceType = props?.deviceType || getConfig().deviceType;

    const ifInPlacements = useMemo(() => {
        const placements = getConfig().placements;

        function placementIncludes(id: PlacementId) {
            return placements?.find(({ placementId }) => placementId === id);
        }

        return {
            topboard: placementIncludes('topboard') ? 'topboard' : '',
            skyscraperleft: placementIncludes('skyscraperleft_1')
                ? 'skyscraperleft_1'
                : '',
            skyscraperright: placementIncludes('skyscraperright_1')
                ? 'skyscraperright_1'
                : '',
            wallpaper: placementIncludes('wallpaper') ? 'wallpaper' : '',
        };
    }, []);

    const { adObj: topboardAdObj, sizes: topboardSizes } = useAd(
        ifInPlacements.topboard,
        false,
    );

    const { adObj: skyscraperleftAdObj, sizes: skyscraperleftSizes } = useAd(
        ifInPlacements.skyscraperleft,
        false,
    );

    const { adObj: skyscraperrightAdObj, sizes: skyscraperrightSizes } = useAd(
        ifInPlacements.skyscraperright,
        false,
    );

    const { adObj: wallpaperAdObj } = useAd(ifInPlacements.wallpaper, false);

    const isChatBotAd = useMemo(() => {
        return (
            skyscraperrightAdObj?.targetId === 'skyscraperright_1' &&
            skyscraperrightAdObj?.source === 'rtb' &&
            skyscraperrightAdObj?.height === 4 &&
            skyscraperrightAdObj?.width === 4
        );
    }, [skyscraperrightAdObj]);

    const [wideWidth, setWideWidth] = React.useState<number>(0);
    useEffect(() => {
        const throttledCallback = throttle(() => {
            const referenceWidth =
                document.getElementById('ad-start-link')?.offsetWidth;
            if (referenceWidth) {
                setWideWidth(referenceWidth);
            }
        }, 200); // Executes at most once per 1000 milliseconds

        if (props.wideLayout) {
            throttledCallback();
            window.addEventListener('resize', throttledCallback);
        }
        return () => {
            window.removeEventListener('resize', throttledCallback);
        };
    }, [props.wideLayout]);

    switch (deviceType) {
        case 'desktop':
            return (
                <div
                    style={
                        wideWidth
                            ? {
                                  maxWidth: wideWidth,
                                  minWidth: 980,
                                  height: 150,
                              }
                            : {
                                  height: 150,
                              }
                    }
                    className={`banners adsbox${
                        skyscraperleftAdObj ? ' banners__is-horse-shoe' : ''
                    } ${
                        isChatBotAd
                            ? 'banners__right-skyscraper-is-chat-bot'
                            : ''
                    }`}
                    data-banner-pos="topbanner"
                    aria-hidden="true"
                >
                    <div>
                        {ifInPlacements.skyscraperleft && (
                            <div className="banners__left-skyscraper">
                                <div className="banners__sticky-skyscraper">
                                    <HorseshoeSwitch
                                        slotAd={props.slotAd}
                                        className=""
                                        id="skyscraperleft_1"
                                        adObj={skyscraperleftAdObj}
                                        sizes={skyscraperleftSizes}
                                    />
                                </div>
                            </div>
                        )}
                        {ifInPlacements.topboard && (
                            <HorseshoeSwitch
                                slotAd={props.slotAd}
                                className="banners__topboard"
                                id="topboard"
                                adObj={topboardAdObj}
                                sizes={topboardSizes}
                            />
                        )}
                        {ifInPlacements.skyscraperright && (
                            <div className="banners__right-skyscraper">
                                <div
                                    id="skyscraperright_1-sticky-container"
                                    className="banners__sticky-skyscraper"
                                >
                                    <HorseshoeSwitch
                                        slotAd={props.slotAd}
                                        className=""
                                        id="skyscraperright_1"
                                        adObj={skyscraperrightAdObj}
                                        sizes={skyscraperrightSizes}
                                    />
                                </div>
                            </div>
                        )}
                        {ifInPlacements.wallpaper && wallpaperAdObj ? (
                            <Wallpaper
                                className="hidden"
                                id="wallpaper"
                                targetId={wallpaperAdObj.targetId}
                            />
                        ) : (
                            <Placeholder className="hidden" id="wallpaper" />
                        )}
                    </div>
                </div>
            );
        case 'tablet':
            return (
                <div
                    className={'banners adsbox banners__is-horse-shoe'}
                    data-banner-pos="topbanner"
                    style={{ height: '150px' }}
                    aria-hidden="true"
                >
                    <div>
                        <HorseshoeSwitch
                            slotAd={props.slotAd}
                            className="banners__topboard"
                            id="topboard"
                            adObj={topboardAdObj}
                            sizes={topboardSizes}
                        />
                    </div>
                </div>
            );
        default:
            return null;
    }
}

const HorseshoeWithErrorBoundary = (props: Props) => (
    <ErrorBoundary fallback={null}>
        <Horseshoe {...props} />
    </ErrorBoundary>
);

export default HorseshoeWithErrorBoundary;

type HorseshoeSwitchProps = {
    id: string;
    className?: string;
    adObj?: AdObj;
    sizes?: Size[];
    slotAd?: boolean;
};

function HorseshoeSwitch(props: HorseshoeSwitchProps) {
    const { id, className, adObj, slotAd } = props;

    if (adObj) {
        return (
            <Banner
                id={id}
                targetId={adObj.targetId}
                className={className}
                bannerClassName={'relative mx-auto'}
                slotAd={slotAd}
                adSize={getSizeFromAdObj(adObj as BannerAdObj)}
            />
        );
    }
    return <Placeholder id={id} className={className} />;
}
