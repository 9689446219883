import { HorseshoeConfig } from '../../../shared/types/podlet';
import { handleLastMinute } from './lastminute';
import { handleCurrency } from './currency';
import { handleContentMarketingFrontpage } from './content-marketing-frontpage';

export function verticalSpesificUpdate(vertical, subvertical) {
    if (vertical === 'travel' && subvertical === 'lastminute') {
        handleLastMinute(500);
    }
}
export function verticalSpesificInit(config: HorseshoeConfig) {
    const { vertical, subvertical } = config;
    if (vertical === 'travel') {
        if (subvertical === 'currency') {
            handleCurrency(config);
        }
    }
}

export function verticalSpecificContentMarketing(config: HorseshoeConfig) {
    const { vertical, subvertical } = config;
    if (vertical === 'realestate') {
        if (!subvertical) {
            handleContentMarketingFrontpage();
        }
    }
}
