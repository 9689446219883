import * as React from 'react';
import { render } from 'react-dom';
import { LocalHostComponent } from './render-localhost';

export function renderLocalTestEnvironment(id: string) {
    if (
        typeof window !== 'undefined' &&
        window?.location?.href === 'http://local.finn.no:3040/' &&
        document
    ) {
        const localDiv = document.createElement('div');
        localDiv.id = id;
        document.body.appendChild(localDiv);

        if (localDiv) {
            render(<LocalHostComponent />, localDiv);
        }
    }
}
