import { Keywords, PageOpts, XandrData } from '../../shared/types/ast';
import { getGlimrKeywords } from './glimr';
import {
    getCMPAdvertisingFromTcf,
    getPageOptsWithConsentKeyword,
    setExternalUid,
} from './external-id';
import { getConfig } from '../config';

/**
 * Currently used for recirc placements. Not ready for Travel pages because enableNRM is removed completely from this logic.
 * The logic in this function is picked from client/index.ts but has removed enableNRM completely.
 * It is counted as true always. That is why it's not Travel-ready, cause travel sends enableNRM false from our podlet node backend.
 */
export function getPageOptsWithClientKeywords(): PageOpts {
    const config = getConfig();

    const xandr = config.xandr as XandrData;

    if (xandr) {
        const preKeywords = xandr?.pageOpts?.keywords;
        const glimrKeywords = getGlimrKeywords();
        if (preKeywords) {
            xandr.pageOpts.keywords = {
                ...preKeywords,
                ...glimrKeywords,
            };
        } else {
            xandr.pageOpts.keywords = {
                ...glimrKeywords,
            };
        }
    }

    const pageOptsWithCmpKeywords = getPageOptsWithConsentKeyword(
        xandr?.pageOpts || {},
        getCMPAdvertisingFromTcf(),
    );
    const pageOptsWithUserIds = setExternalUid(pageOptsWithCmpKeywords);
    return { ...xandr, ...pageOptsWithUserIds };
}

export const getKeywordByKey = (key: string): string[] | undefined => {
    const config = getConfig();
    const { keywords } = config?.xandr?.pageOpts;
    if (keywords) {
        return keywords[key];
    } else return undefined;
};

export function dedupeKeywords(oldKeys: Keywords, newKeys: Keywords): Keywords {
    const result: Keywords = {};

    // Merge oldKeys and newKeys
    const allKeys = { ...oldKeys, ...newKeys };

    // Iterate over each key-value pair
    for (const key in allKeys) {
        const newValues = newKeys[key];
        const oldValues = oldKeys[key];

        // If key exists in newKeys, use newValues, else use oldValues
        result[key] = newValues !== undefined ? newValues : oldValues;
    }

    return result;
}
