import { AfsPageOptions } from '../../shared/types/ast';
import { getConfig, getFeatureStatus } from '../config';
import { UNLEASH_FEATURE_NAMES } from '../../shared/unleash/feature-names';
import { displayAdsLog } from './debug';

const STYLE_ID = {
    DESKTOP: '5268803732',
    MOBILE: '4302767753',
};

const afs = {
    pubId: 'partner-pub-4700071094164059',
    query: 'search',
};

const CONTAINER_ID = 'afscontainer1';

const { deviceType, pageType, vertical } = getConfig();

function getQueryFromElement() {
    const el = document.getElementById(CONTAINER_ID);
    if (el) {
        const query = el.getAttribute('data-query');
        if (query) {
            return query;
        }
        const category = el.getAttribute('data-category');
        if (category) {
            return category;
        }
        const subCategory = el.getAttribute('data-subcategory');
        if (subCategory) {
            return subCategory;
        }
    }
    return '';
}
export function triggerGoogleAfs() {
    if (typeof window === 'undefined') return;

    const googleAfs = getFeatureStatus(UNLEASH_FEATURE_NAMES.googleAFSEnabled);
    if (!googleAfs) return;

    if (pageType === 'result' && vertical === 'bap') {
        const query = getQueryFromElement();
        triggerAfsByQuery(query);
    }
}

function triggerAfsByQuery(query?: string) {
    if (query) {
        const pageOptions: AfsPageOptions = {
            pubId: afs.pubId,
            query: query,
            styleId:
                deviceType === 'mobile' ? STYLE_ID.MOBILE : STYLE_ID.DESKTOP,
            adsafe: 'high',
            resultsPageBaseUrl: window.location.href,
            resultsPageQueryParam: 'q',
            number: 1,
        };

        const adblock1 = {
            container: CONTAINER_ID,
        };

        try {
            displayAdsLog('triggerAfsByQuery()', pageOptions);
            window._googCsa('ads', pageOptions, adblock1);
        } catch (error) {
            console.error('Error in google ad sense [for search]: ', error);
        }
    }
}
