import { Filter } from './types/podlet';
import { Subvertical, Vertical } from './types/ast';

export function travelSearchKeys(
    searchFilters: Filter,
    vertical: Vertical,
    subvertical: Subvertical,
) {
    if (!searchFilters.search_key && vertical === 'travel' && subvertical) {
        if (subvertical === 'flight') {
            searchFilters.search_key = 'SEARCH_ID_TRAVEL_FLIGHT';
        } else if (subvertical === 'prepackage') {
            searchFilters.search_key = 'SEARCH_ID_TRAVEL_PREPACKAGE';
        } else if (subvertical === 'lastminute') {
            searchFilters.search_key = 'SEARCH_ID_TRAVEL_LASTMINUTE';
        } else if (subvertical === 'currency') {
            searchFilters.search_key = 'SEARCH_ID_TRAVEL_CURRENCY';
        } else if (subvertical === 'holiday_rental') {
            searchFilters.search_key = 'SEARCH_ID_TRAVEL_HOLIDAYRENTALS';
        } else if (subvertical === 'hotel') {
            searchFilters.search_key = 'SEARCH_ID_TRAVEL_HOTEL';
        }
    }
    return searchFilters;
}

export interface ExpectedTravelFilter {
    page?: number;
    destination?: {
        country: string;
        city?: string;
        continent?: string;
    };
}

export function preProcessTravelFilter(
    expectedFilter: ExpectedTravelFilter,
    vertical: Vertical,
    subvertical?: Subvertical,
): Filter {
    let filter = expectedFilter ? { ...expectedFilter } : ({} as Filter);

    if (vertical === 'travel') {
        const { destinasjon, destination, til } = filter;
        const location = destination || destinasjon;

        if (location) {
            if (typeof location === 'object') {
                const { city, country, by, land, continent, kontinent } =
                    location as any;
                const safeCity = by || city;
                if (safeCity) {
                    filter['city'] = [safeCity];
                }
                const safeCountry = land || country;
                if (safeCountry) {
                    filter['country'] = [safeCountry];
                }
                const safeContinent = kontinent || continent;
                if (safeContinent) {
                    filter['continent'] = [safeContinent];
                }
            }
            if (typeof location === 'string') {
                const locations = location.split(';');
                if (locations.length > 0) {
                    filter['city'] = [locations[0]];
                }
                if (locations.length > 1) {
                    filter['country'] = [locations[1]];
                }
                if (locations.length > 2) {
                    filter['continent'] = [locations[0]];
                }
            }

            delete filter.destinasjon;
            delete filter.destination;
        }

        if (til && Array.isArray(til) && til.length > 0) {
            const locations = til[0].split(';');

            if (locations.length > 0) {
                filter['city'] = [locations[locations.length - 1]];
            }
            if (locations.length > 1) {
                filter['country'] = [locations[locations.length - 2]];
            }
            if (locations.length > 2) {
                filter['continent'] = [locations[0]];
            }

            delete filter.til;
        }

        filter.side = filter.page || filter.side;
    }

    return travelSearchKeys(filter, vertical, subvertical);
}
